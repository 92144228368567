import React, { useState } from "react";
import { InboxOutlined } from "@ant-design/icons";
import { message, Upload } from "antd";
import * as Papa from "papaparse";
import { apiPost } from "app/services/apiService";
import { API_ENDPOINT_PROPERTY_CREATE_BULK } from "app/scenes/property/property.constants";
import { useDispatch } from "react-redux";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import Loader from "app/shared/Loader";

const { Dragger } = Upload;

function BulkCreate(props) {
  const [jsonResult, setJsonResult] = useState(null);
  const [isLoading, setLoading] = useState(false);
  const draggerprops = {
    name: "file",
    multiple: true,
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        handleFileUpload(info.file);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };
  const dispatch = useDispatch();

  const handleFileUpload = (file) => {
    const reader = new FileReader();

    reader.onload = (e) => {
      const text = e.target.result;
      const result = Papa.parse(text, { header: true });
      handleBulkCreate(result.data);
    };

    reader.readAsText(file.originFileObj);
  };

  const handleBulkCreate = async (payload) => {
    setLoading(true);
    const response = await apiPost(API_ENDPOINT_PROPERTY_CREATE_BULK, {
      property_list: payload,
    });
    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
      props.onSuccess();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Dragger {...draggerprops}>
      <p className="ant-upload-drag-icon">
        <InboxOutlined />
      </p>
      <p className="ant-upload-text">
        Click or drag file to this area to upload
      </p>
      <p className="ant-upload-hint">
        Support for a single or bulk upload. Strictly prohibited from uploading
        company data or other banned files.
      </p>
    </Dragger>
  );
}

export default BulkCreate;
