import React, { useState, useRef } from "react";
import MapGL, { Marker, Popup } from "react-map-gl";
// import Geocoder from "react-mapbox-gl-geocoder";
import { useNavigate } from "react-router-dom";
import axios from "axios";
import "./MapBox.scss";
import PlaceIcon from "@mui/icons-material/Place";
import { colorPicker } from "app/utils/helpers/color.helper";
import Button from "../Button";
import { useEffect } from "react";
// Please be a decent human and don't abuse my Mapbox API token.
// If you fork this sandbox, replace my API token with your own.
// Ways to set Mapbox token: https://uber.github.io/react-map-gl/#/Documentation/getting-started/about-mapbox-tokens
const MAPBOX_TOKEN =
  "pk.eyJ1IjoidGFic3MiLCJhIjoiY2xwc2lyanhqMDRidTJxcnp1N2JraWhlbCJ9.32vvhKivtLoZO-AOP7vdCg";

const MapBox = (props) => {
  const [viewport, setViewport] = useState({
    latitude: 27.0238,
    longitude: 74.2179,
    zoom: 11.4,
  });
  const [result, setResult] = useState({
    latitude: 27.0238,
    longitude: 74.2179,
  });

  const [pickMarker, setPickMarker] = useState(false);
  const [ready, setReady] = useState(false);

  useEffect(() => {
    console.log("default value", props.defaultValue);
    if (props.defaultValue) {
      setResult(props.defaultValue);
      setViewport({
        ...props.defaultValue,
        zoom: 12,
      });
    }
  }, [props.defaultValue]);
  const final = () => {
    axios
      .get(
        `https://api.mapbox.com/geocoding/v5/mapbox.places/${viewport.longitude},${viewport.latitude}.json?access_token=pk.eyJ1IjoidGFic3MiLCJhIjoiY2xwc2lyanhqMDRidTJxcnp1N2JraWhlbCJ9.32vvhKivtLoZO-AOP7vdCg`
      )
      .then((res) => {
        const { data } = res;
        setResult({
          latitude: viewport.latitude,
          longitude: viewport.longitude,
          location: data.features[0].place_name,
        });
      });
  };

  const mapRef = useRef();
  const handleViewportChange = (newViewport, item) => {
    setResult({ ...result, location: "" });

    setViewport(newViewport);
    // item.isPanning != true &&
    //   item.isZooming != true &&
    //   setResult({
    //     latitude: newViewport.latitude,
    //     longitude: newViewport.longitude,
    //     location: item.place_name,
    //   });
  };

  // const onSelected = (viewPort, item) => {
  //   navigate("/map");
  //   console.log(viewPort);
  //   setViewport({ ...viewPort, zoom: viewPort.zoom * 20 });
  //   console.log('Selected: ', item)
  // }

  const onDragStart = React.useCallback(() => {
    console.log("run");
  }, []);

  return (
    <>
      <div>
        {props.pickMarker && (
          <div className="select-btn">
            <Button
              onClick={() =>
                props.selectCordinate({
                  lat: viewport.latitude,
                  long: viewport.longitude,
                })
              }
            >
              Select Marker
            </Button>
          </div>
        )}
      </div>
      <MapGL
        ref={mapRef}
        {...viewport}
        width="100%"
        height="100%"
        onViewportChange={handleViewportChange}
        mapboxApiAccessToken={MAPBOX_TOKEN}
        onTouchStart={onDragStart}
      >
        {props.pickMarker ? (
          <>
            <Marker longitude={viewport.longitude} latitude={viewport.latitude}>
              <PlaceIcon style={{ color: colorPicker("red") }} />
            </Marker>
            <Popup
              longitude={viewport.longitude}
              latitude={viewport.latitude}
              closeButton={false}
            >
              <p>{result.location}</p>
            </Popup>
          </>
        ) : (
          <Marker longitude={result.longitude} latitude={result.latitude}>
            <PlaceIcon style={{ color: colorPicker("red") }} />
          </Marker>
        )}
      </MapGL>
    </>
  );
};

export default MapBox;
