import Typography from "app/shared/Typography";
import PromotorIcon from "assets/images/promote.svg";
import HomeIcon from "assets/images/home.svg";
import ComissionIcon from "assets/images/comission.svg";
import VisitIcon from "assets/images/visit.svg";
import HomeOutlinedIcon from "@mui/icons-material/HomeOutlined";
import MapOutlinedIcon from "@mui/icons-material/MapOutlined";
import "./TopPerformers.scss";
import { colorPicker } from "app/utils/helpers/color.helper";
import { Avatar, Image, Skeleton } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import {
  API_ENDPOINT_FETCH_BROKER_REFERRALS,
  API_ENDPOINT_USER_INFO,
} from "app/scenes/brokers/brokers.constants";
import { useEffect, useState } from "react";
import Loader from "app/shared/Loader";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import { apiGet } from "app/services/apiService";
import { API_ENDPOINT_DASHBOARD_FETCH_TOP_PERFORMERS } from "../../dashboard.constants";
const imageType = {
  HOME: HomeIcon,
  VISIT: VisitIcon,
  COMISSION: ComissionIcon,
};
export default function TopPerformers(props) {
  const [userList, setUserList] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchTopPerformers();
  }, []);
  const fetchTopPerformers = async () => {
    setLoading(true);
    const response = await apiGet(
      API_ENDPOINT_DASHBOARD_FETCH_TOP_PERFORMERS +
        "?start=2024-01-14T16:04:35.000Z&end=2024-01-14T16:04:35.000Z"
    );
    if (response.status) {
      setUserList(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };
  return isLoading ? (
    <Loader />
  ) : (
    <div className="TopPerformers">
      <div className="TopHeader">
        <Typography variant="h5">Top Performers</Typography>
        <Typography variant="xs" color="red" style={{ cursor: "pointer" }}>
          See All
        </Typography>
      </div>
      <div className="UserList">
        {/* {userList.slice(0, 5).map((user, key) => {
          return <UserInfoCard data={user} key={key} />;
        })} */}
      </div>
    </div>
  );
}

const UserInfoCard = (props) => {
  const [userInfo, setUserInfo] = useState(null);
  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    fetchUserInfo();
  }, []);

  const fetchUserInfo = async () => {
    setLoading(true);
    const response = await apiGet(API_ENDPOINT_USER_INFO + props.data.id);
    if (response.status) {
      setUserInfo(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };
  return isLoading ? (
    <Skeleton />
  ) : (
    <div className="UserInfo">
      <div className="UserInfoAttribute">
        <Avatar
          size={28}
          src={userInfo && userInfo.profileInfo?.user_profile?.avatar}
          icon={
            !userInfo.profileInfo?.user_profile?.avatar ? (
              <UserOutlined />
            ) : null
          }
          style={{ objectFit: "contain" }}
        />
        <Typography
          variant="caption"
          color="gray-dark"
          style={{ marginLeft: 8 }}
        >
          {/* {user.user_profile?.first_name || "NA"}{" "}
      {user.user_profile?.last_name} */}
          {(userInfo && userInfo.profileInfo?.user_profile?.first_name) || "NA"}
          {""}
          {userInfo && userInfo.profileInfo?.user_profile?.last_name}
        </Typography>
      </div>
      <div className="UserInfoAttribute">
        <Typography variant="caption" style={{ fontWeight: 600 }}>
          {props.data.score} <small style={{ fontWeight: 400 }}>pts</small>
        </Typography>
      </div>
    </div>
  );
};
