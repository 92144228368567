import { Form } from "antd";
import Button from "app/shared/Button";
import Input from "app/shared/InputBox";
import SearchSelector from "app/shared/SearchSelector";
import "./MakeAnnouncement.scss";
import TextEditor from "app/shared/TextEditor";
import { ANNOUNCEMENT_SEND_TO_MAP } from "app/constants/global.constants";
import Selector from "app/shared/Selector";
import { useState } from "react";
import { apiPost } from "app/services/apiService";
import { API_ENDPOINT_ANNOUNCEMENT_CREATE } from "../../announcement.constants";
import { useDispatch } from "react-redux";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";

export default function MakeAnnouncement(props) {
  const [subject, setSubject] = useState("");
  const [sendTo, setSendTo] = useState("");
  const [projectId, setProjectId] = useState("");

  const [description, setDescription] = useState("");
  const [scheduledTime, setScheduleTime] = useState("");

  const dispatch = useDispatch();
  const createAnnouncement = async () => {
    const payload = {
      subject: subject,
      send_to: sendTo,
      description: description,
      scheduled_at: scheduledTime || null,
      mode: "SEND",
      projectId: projectId,
    };

    const response = await apiPost(API_ENDPOINT_ANNOUNCEMENT_CREATE, payload);
    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
      props.onCreate && props.onCreate();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
  };
  return (
    <div className="MakeAnnouncement">
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={createAnnouncement}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
        style={{ marginTop: 32 }}
      >
        <div
          className="form-row"
          style={{
            justifyContent: "flex-start",
            display: "flex",
            alignItems: "center",
            marginBottom: 24,
          }}
        >
          <div
            className="input-elements"
            style={{ marginRight: 24, width: "48%" }}
          >
            <Form.Item
              name={"subject"}
              rules={[
                {
                  required: true,
                  message: "Please check your input",
                },
              ]}
              // initialValue={planName}
              style={{ marginBottom: 0 }}
            >
              <Input
                placeholder={"Subject"}
                name={"Subject"}
                onChange={(e) => setSubject(e.target.value)}
                //   defaultValue={}
              />
            </Form.Item>
          </div>

          <div className="input-elements" style={{ width: "48%" }}>
            <Form.Item
              name={"send_to"}
              style={{ marginBottom: 0, width: "100%" }}
            >
              <Selector
                placeholder="Send To"
                serviceType="STATIC"
                valueKey="value"
                labelKey="label"
                onChange={(value) => setSendTo(value)}
                height={50}
                //   defaultValue={projectName}
                staticData={ANNOUNCEMENT_SEND_TO_MAP}
              />
            </Form.Item>
          </div>
        </div>
        <div
          className="form-row"
          style={{
            justifyContent: "flex-start",
            display: "flex",
            alignItems: "center",
            marginBottom: 24,
          }}
        >
          <div className="input-elements" style={{ width: "48%" }}>
            <Form.Item
              name={"project"}
              style={{ marginBottom: 0, width: "100%" }}
            >
              <Selector
                placeholder="Project"
                serviceType="PROPERTY_PROJECTS"
                valueKey="id"
                labelKey="name"
                onChange={(value) => setProjectId(value)}
                height={50}
                //   defaultValue={projectName}
              />
            </Form.Item>
          </div>
        </div>

        <div className="form-row">
          <div style={{ width: "100%" }}>
            <TextEditor onChange={(data) => setDescription(data)} />
          </div>
        </div>

        <div className="action-buttons">
          <Form.Item style={{ width: 240 }}>
            <Button
              type="primary"
              size="large"
              style={{ width: "100%" }}
              htmlType="submit"
              //   loading={isLoading}
            >
              Send
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}
