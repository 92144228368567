import Typography from "app/shared/Typography";
import { ResponsivePie } from "@nivo/pie";
import { colorPicker } from "app/utils/helpers/color.helper";
import { API_ENDPOINT_DASHBOARD_FETCH_SALES_RATIO } from "../../dashboard.constants";
import { useDispatch } from "react-redux";
import { useEffect, useState } from "react";
import { apiGet } from "app/services/apiService";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";

export default function SalesAndRent(props) {
  const [isLoading, setLoading] = useState(true);
  const [salesInfo, setSalesInfo] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    fetchSalesInfo();
  }, []);
  const fetchSalesInfo = async () => {
    setLoading(true);
    const response = await apiGet(
      API_ENDPOINT_DASHBOARD_FETCH_SALES_RATIO +
        "?start=2024-01-14T16:04:35.000Z&end=2024-01-14T16:04:35.000Z"
    );
    if (response.status) {
      setSalesInfo(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };

  const processPieData = (data) => {
    return [
      {
        id: "commercial",
        label: "Commerical",
        value: data?.commercial || 0,
        color: colorPicker("yellow"),
      },
      {
        id: "residental",
        label: "Residental",
        value: data?.residential || 0,
        color: colorPicker("red"),
      },
    ];
  };

  return (
    <div
      className="SalesAndRent"
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div
        style={{
          display: "flex",
          alignItems: "flex-start",
          justifyContent: "space-between",
        }}
      >
        <Typography>Sales Ratio</Typography>

        <Popover
          placement="bottom"
          title={"Sales Ratio"}
          content={"Understand what type of property you sell the most"}
        >
          <InfoCircleOutlined />
        </Popover>
      </div>
      <div style={{ height: "100%" }}>
        <ResponsivePie
          data={processPieData(salesInfo)}
          margin={{ top: 40, right: 80, bottom: 80, left: 80 }}
          innerRadius={0.5}
          padAngle={0.7}
          cornerRadius={0}
          activeOuterRadiusOffset={8}
          borderWidth={1}
          colors={["#27ae60", "#e74c3c"]}
          enableArcLinkLabels={false}
          arcLinkLabelsSkipAngle={10}
          arcLinkLabelsTextColor="#333333"
          arcLinkLabelsThickness={2}
          arcLinkLabelsColor={{ from: "color" }}
          arcLabelsSkipAngle={10}
          arcLabelsTextColor={{
            from: "color",
            modifiers: [["darker", 2]],
          }}
          fill={[
            {
              match: {
                id: "ruby",
              },
              id: "dots",
            },
            {
              match: {
                id: "c",
              },
              id: "dots",
            },
          ]}
          legends={[
            {
              anchor: "bottom",
              direction: "row",
              justify: false,
              translateX: 0,
              translateY: 56,
              itemsSpacing: 0,
              itemWidth: 100,
              itemHeight: 18,
              itemTextColor: "#999",
              itemDirection: "left-to-right",
              itemOpacity: 1,
              symbolSize: 14,
              symbolShape: "circle",
              effects: [
                {
                  on: "hover",
                  style: {
                    itemTextColor: "#000",
                  },
                },
              ],
            },
          ]}
        />
      </div>
    </div>
  );
}
