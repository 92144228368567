import { Outlet } from "react-router";
import AuthIllustration from "assets/images/illustrations/authIllustration.png";
import AppLogo from "assets/images/appLogo.svg";
import "./styles/onboarding.scss";

export default function Onboarding(props) {
  return (
    <div className="onboarding">
      <div className="container">
        <div className="left-container">
          <div className="forms">
            <div className="appLogo">
              <img src={AppLogo} alt="appLogo" />
            </div>
            <Outlet />
          </div>
        </div>
        <div className="right-container">
          <img src={AuthIllustration} alt="illustrationAuth" />
        </div>
      </div>
    </div>
  );
}
