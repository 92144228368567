import Button from "app/shared/Button";
import Input from "app/shared/InputBox";
import { useState } from "react";

export default function AreaCalculator(props) {
  const [length, setLength] = useState("");
  const [width, setWidth] = useState("");

  const handleCalculate = () => {
    props.onCalculate &&
      props.onCalculate(parseFloat(length) * parseFloat(width));
  };
  return (
    <div className="ItemRow" style={{ width: "100%" }}>
      <div style={{ display: "flex", alignItems: "center", marginBottom: 24 }}>
        <div style={{ width: "48%" }}>
          <Input
            placeholder={`L (${props.unitSizeType || "ft"})`}
            label={`L (${props.unitSizeType || "ft"})`}
            onChange={(e) => setLength(e.target.value)}
          />
        </div>

        <div style={{ width: "48%" }}>
          <Input
            placeholder={`W (${props.unitSizeType || "ft"})`}
            label={`W (${props.unitSizeType || "ft"})`}
            onChange={(e) => setWidth(e.target.value)}
          />
        </div>
      </div>

      <div>
        <Button onClick={handleCalculate}>Calculate</Button>
      </div>
    </div>
  );
}
