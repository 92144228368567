// import ProtectedRoute from "app/utils/ProtectedRoute";
import ProtectedRoute from "app/utils/ProtectedRoute";
import Promoters from ".";
import PromoterList from "./components/PromoterList";
//eslint-disable-next-line
export default {
  path: "/promoters",
  element: <Promoters />,
  children: [
    {
      path: "/promoters/list",
      element: (
        <ProtectedRoute accessKey="PROMOTER_VIEW">
          <PromoterList />
        </ProtectedRoute>
      ),
      exact: true,
    },
  ],
};
