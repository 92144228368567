import "../../styles/auth.scss";
import Login from "../login";
import Register from "../register";
import TabBar from "app/shared/TabBar";
const tabContnet = [
  {
    label: "Login",
    key: 1,
    children: <Login />,
  },
  {
    label: "Register",
    key: 2,
    children: <Register />,
  },
];
export default function Authenticate(props) {
  return <TabBar items={tabContnet} />;
}
