// import ProtectedRoute from "app/utils/ProtectedRoute";
import ProtectedRoute from "app/utils/ProtectedRoute";
import Property from ".";
import InvestorList from "./components/InvestorList";
import ProjectList from "./components/ProjectList";
import PropertyCreate from "./components/PropertyCreate";
import ProjectDetails from "./components/ProjectDetails";
import PropertyDetails from "./components/PropertyDetails";
import PropertyEdit from "./components/PropertyEdit";
import PropertyList from "./components/propertylist";
import CreateProject from "./components/CreateProject";
import AmentityList from "./components/AmentityList";
import FacilityList from "./components/FacilityList";
import PropertyTypes from "./components/PropertyTypes";
import PaymentPlans from "./components/PaymentPlans";
import BankList from "./components/BankList";
import PropertyManage from "./components/PropertyManage";
import PropertyDevelopers from "./components/PropertyDevelopers";
import PropertyCommunity from "./components/PropertyCommunity";
import PropertySales from "./components/PropertySales";
import AddUnit from "./components/AddUnit";
import InventoryManagement from "./components/InventoryManagement";
//eslint-disable-next-line
export default {
  path: "/property",
  element: <Property />,
  children: [
    {
      path: "/property/list",
      element: (
        <ProtectedRoute accessKey="PROPERTY_LISTING_VIEW">
          <PropertyList />
        </ProtectedRoute>
      ),
      exact: true,
    },
    {
      path: "/property/project/unit/create/:paramProjectId",
      element: (
        <ProtectedRoute accessKey="PROPERTY_LISTING_MANAGE">
          <AddUnit />
        </ProtectedRoute>
      ),
      exact: true,
    },
    {
      path: "/property/project/unit/edit/:paramProjectId/:unitId",
      element: (
        <ProtectedRoute accessKey="PROPERTY_LISTING_MANAGE">
          <AddUnit editMode />
        </ProtectedRoute>
      ),
      exact: true,
    },
    {
      path: "/property/edit/:propertyId",
      element: (
        <ProtectedRoute accessKey="PROPERTY_LISTING_MANAGE">
          <PropertyEdit />
        </ProtectedRoute>
      ),
      exact: true,
    },
    {
      path: "/property/:propertyId",
      element: (
        <ProtectedRoute accessKey="PROPERTY_LISTING_VIEW">
          <PropertyDetails />
        </ProtectedRoute>
      ),
      exact: true,
    },

    {
      path: "/property/investors",
      element: (
        <ProtectedRoute accessKey="PROPERTY_LISTING_VIEW">
          <InvestorList />
        </ProtectedRoute>
      ),
      exact: true,
    },
    {
      path: "/property/projects",
      element: (
        <ProtectedRoute accessKey="PROPERTY_LISTING_VIEW">
          <ProjectList />
        </ProtectedRoute>
      ),
      exact: true,
    },
    {
      path: "/property/project/:projectId",
      element: (
        <ProtectedRoute accessKey="PROPERTY_LISTING_VIEW">
          <ProjectDetails />
        </ProtectedRoute>
      ),
      exact: true,
    },
    {
      path: "/property/project/create",
      element: (
        <ProtectedRoute accessKey="PROPERTY_LISTING_VIEW">
          <CreateProject />
        </ProtectedRoute>
      ),
      exact: true,
    },
    {
      path: "/property/project/edit/:projectId",
      element: (
        <ProtectedRoute accessKey="PROPERTY_LISTING_VIEW">
          <CreateProject editMode={true} />
        </ProtectedRoute>
      ),
      exact: true,
    },

    {
      path: "/property/amentities",
      element: (
        <ProtectedRoute accessKey="PROPERTY_LISTING_MANAGE">
          <AmentityList />
        </ProtectedRoute>
      ),
      exact: true,
    },

    {
      path: "/property/facilities",
      element: (
        <ProtectedRoute accessKey="PROPERTY_LISTING_MANAGE">
          <FacilityList />
        </ProtectedRoute>
      ),
      exact: true,
    },
    {
      path: "/property/types",
      element: (
        <ProtectedRoute accessKey="PROPERTY_LISTING_MANAGE">
          <PropertyTypes />
        </ProtectedRoute>
      ),
      exact: true,
    },

    {
      path: "/property/payment_plan",
      element: (
        <ProtectedRoute accessKey="PROPERTY_LISTING_MANAGE">
          <PaymentPlans />
        </ProtectedRoute>
      ),
      exact: true,
    },

    {
      path: "/property/banks",
      element: (
        <ProtectedRoute accessKey="PROPERTY_LISTING_MANAGE">
          <BankList />
        </ProtectedRoute>
      ),
      exact: true,
    },

    {
      path: "/property/manage",
      element: (
        <ProtectedRoute accessKey="PROPERTY_LISTING_MANAGE">
          <PropertyManage />
        </ProtectedRoute>
      ),
      exact: true,
    },

    {
      path: "/property/inventory",
      element: (
        <ProtectedRoute accessKey="PROPERTY_LISTING_MANAGE">
          <InventoryManagement />
        </ProtectedRoute>
      ),
      exact: true,
    },
    {
      path: "/property/developers",
      element: (
        <ProtectedRoute accessKey="PROPERTY_LISTING_MANAGE">
          <PropertyDevelopers />
        </ProtectedRoute>
      ),
      exact: true,
    },

    {
      path: "/property/community",
      element: (
        <ProtectedRoute accessKey="PROPERTY_LISTING_MANAGE">
          <PropertyCommunity />
        </ProtectedRoute>
      ),
      exact: true,
    },
    {
      path: "/property/sales",
      element: (
        <ProtectedRoute accessKey="PROPERTY_LISTING_MANAGE">
          <PropertySales />
        </ProtectedRoute>
      ),
      exact: true,
    },
  ],
};
