import React from "react";
import {
  LogoutOutlined,
  AppstoreOutlined,
  HomeOutlined,
  UserOutlined,
  FileSearchOutlined,
  LineChartOutlined,
  SettingOutlined,
  CalendarOutlined,
  NotificationOutlined,
  PercentageOutlined,
} from "@ant-design/icons";

import { ConfigProvider, Layout, Menu, theme } from "antd";
import { Outlet, useNavigate } from "react-router";
import "./masterlayout.scss";
import AppLogo from "assets/images/appLogo.png";
import DummyAvatar from "assets/images/DummyAvatar.png";

import Typography from "../Typography";
import color from "theme/color";
import HeaderContent from "app/shared/Header";
import { useEffect } from "react";
import { API_ENDPOINT_DASHBOARD_HUDDLE } from "app/scenes/dashboard/dashboard.constants";
import { SAVE_USER_INFO } from "app/scenes/auth/redux/auth.action";
import { connect, useDispatch } from "react-redux";
import { apiGet } from "app/services/apiService";
import { useState } from "react";
import Loader from "../Loader";
import UserAvatar from "../UserAvatar";
import { FeatureFlagsProvider } from "app/utils/FeatureFlag/FeatrueFlag";
function getItem(label, key, roleKey, icon, children, type) {
  return {
    key,
    icon,
    roleKey,
    children,
    label,
    type,
  };
}
const items = [
  getItem("Dashboard", "/dashboard", "NONE", <AppstoreOutlined />),
  getItem("Properties", "sub2", "PROPERTY_LISTING_VIEW", <HomeOutlined />, [
    getItem("Unit List", "/property/list", "PROPERTY_LISTING_VIEW"),
    getItem("Projects", "/property/projects", "PROPERTY_LISTING_MANAGE"),
    getItem("Manage Units", "/property/manage", "PROPERTY_LISTING_MANAGE"),
    getItem(
      "Manage Inventory",
      "/property/inventory",
      "PROPERTY_LISTING_MANAGE"
    ),

    // getItem(
    //   "Create Project",
    //   "/property/project/create",
    //   "PROPERTY_LISTING_MANAGE"
    // ),

    getItem("Investors", "/property/investors", "PROPERTY_LISTING_MANAGE"),
    getItem("Amentities", "/property/amentities", "PROPERTY_LISTING_MANAGE"),
    getItem("Facilities", "/property/facilities", "PROPERTY_LISTING_MANAGE"),
    getItem("Categories", "/property/types", "PROPERTY_LISTING_MANAGE"),
    getItem("Developers", "/property/developers", "PROPERTY_LISTING_MANAGE"),
    getItem("Community", "/property/community", "PROPERTY_LISTING_MANAGE"),

    getItem("Banks", "/property/banks", "PROPERTY_LISTING_MANAGE"),
    getItem("Sale History", "/property/sales", "PROPERTY_LISTING_MANAGE"),

    getItem(
      "Payment Plans",
      "/property/payment_plan",
      "PROPERTY_LISTING_MANAGE"
    ),
  ]),
  getItem("Broker", "brokers/list", "BROKER_VIEW", <UserOutlined />),
  getItem("Promoter", "promoters/list", "PROMOTER_VIEW", <UserOutlined />),
  getItem("Employee", "employee/list", "EMPLOYEE_VIEW", <UserOutlined />),
  getItem("Customers", "customer/list", "EMPLOYEE_VIEW", <UserOutlined />),

  getItem("Leads", "leads/list", "LEAD_VIEW", <FileSearchOutlined />),
  getItem("Reports", "reports/list", "NONE", <LineChartOutlined />),
  getItem("Leaves", "/leaves/dashboard", "LEAVE_VIEW", <CalendarOutlined />),
  getItem(
    "Announcement",
    "/announcement/list",
    "LEAVE_VIEW",
    <NotificationOutlined />
  ),

  getItem("Offers", "/offers/list", "LEAVE_VIEW", <PercentageOutlined />),
  getItem("Settings", "settings", "NONE", <SettingOutlined />),
];

const { Header, Content, Footer, Sider } = Layout;
const MasterLayout = (props) => {
  const [isLoading, setLoading] = useState(true);
  const {
    token: { colorBgContainer },
  } = theme.useToken();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    fetchDependencies();
  }, []);

  console.log("item is:", items);
  const onboardingFlow = {
    3002: "/onboard/profile",
    3003: "/onboard/profile_document",
    3004: "/onboard/profile_bank",
  };
  const fetchDependencies = async () => {
    const response = await apiGet(
      API_ENDPOINT_DASHBOARD_HUDDLE
    );
    if (response.status) {
      dispatch({ type: SAVE_USER_INFO, payload: response.data.userInfo });

      if (response.data.onboarding) {
        navigate(onboardingFlow[response.data.onboarding]);
      } else {
        console.log(response);

        setLoading(false);
      }
    } else {
      localStorage.clear();
      navigate("/auth/authenticate");
    }
  };

  const handleLogout = () => {
    localStorage.clear();
    navigate("/auth/authenticate");
  };
  const handleMenu = (menu) => {
    navigate(menu.key);
  };

  const filterOutMenu = (menus) => {
    let finalMenus = [];
    menus.forEach((menu) => {
      if (
        (props.userAccess &&
          menu.roleKey !== "NONE" &&
          props.userAccess.includes(menu.roleKey)) ||
        menu.roleKey === "NONE"
      ) {
        let pushable = menu;
        if (pushable.children) {
          pushable.children = pushable.children.filter((child) =>
            props.userAccess.includes(child.roleKey)
          );
        }
        finalMenus.push(pushable);
      }
    });
    return finalMenus;
  };
  return (
    <FeatureFlagsProvider feature_flag_values={props.userAccess}>
      <Layout
        style={{ height: "100vh", backgroundColor: "#F0F2FD" }}
        className="masterLayout"
      >
        {isLoading ? (
          <Loader />
        ) : (
          <>
            <Sider
              breakpoint="md"
              collapsedWidth="0"
              onBreakpoint={(broken) => {
                console.log(broken);
              }}
              style={{ position: "absolute", height: "100vh", zIndex: 1 }}
              onCollapse={(collapsed, type) => {
                console.log(collapsed, type);
              }}
              width={256}
              theme="light"
            >
              <div className="leftSideNav">
                <div className="LogoMenuContainer">
                  <div className="appLogoContainer">
                    <img
                      src={AppLogo}
                      alt="appLogo"
                      className="profileImage"
                      style={{ height: "1.5rem" }}
                    />
                  </div>
                  <div className="userInfoContainer">
                    {/* <img
                      src={
                        (props.userInfo &&
                          props.userInfo.user_profile.avatar) ||
                        DummyAvatar
                      }
                      alt="userProfileImage"
                      className="profileImage"
                    /> */}
                    <div className="profileImage">
                      <UserAvatar
                        avatar={
                          props.userInfo && props.userInfo.user_profile.avatar
                        }
                      />
                    </div>
                    <div className="userInfo">
                      <Typography variant="body" style={{ fontSize: 16 }}>
                        {props.userInfo &&
                          props.userInfo.user_profile.first_name}{" "}
                        {props.userInfo &&
                          props.userInfo.user_profile.last_name}
                      </Typography>
                      <Typography variant="caption" color="gray_text">
                        {props.userInfo && props.userInfo.email}
                        
                      </Typography>
                    </div>
                  </div>
                  <div className="menuContainer">
                    <ConfigProvider
                      theme={{
                        components: {
                          Menu: {
                            itemBorderRadius: 0,
                            inlineIndent: 0,
                            groupTitleColor: color.red,
                            colorBgTextActive: color.red,
                          },
                        },
                      }}
                    >
                      <Menu
                        onClick={handleMenu}
                        defaultSelectedKeys={["1"]}
                        defaultOpenKeys={["sub1"]}
                        mode="inline"
                        theme="light"
                        items={filterOutMenu(items)}
                        style={{
                          color: color.gray_dark,
                          width: "100%",
                          margin: 0,
                        }}
                      />
                    </ConfigProvider>
                  </div>
                </div>
                <div className="logout" onClick={handleLogout}>
                  <LogoutOutlined style={{ color: "#8792A4" }} />
                  <Typography
                    variant="button"
                    style={{ marginLeft: 20, color: "#8792A4" }}
                  >
                    Logout
                  </Typography>
                </div>
              </div>
            </Sider>
            <Layout
              className="MasterLayoutContent"
              style={{
                height: "100vh",
                overflowY: "auto",
                backgroundColor: "#F0F2FD",
              }}
            >
              <Header
                style={{
                  padding: 0,
                  background: "#F0F2FD",
                  height: 88,
                }}
              >
                <HeaderContent {...props} />
              </Header>
              <Content
                style={{
                  backgroundColor: "#F0F2FD",
                  padding: 24,
                }}
              >
                <div
                  style={{
                    minHeight: 360,
                    background: "#F0F2FD",
                    height: "100%",
                  }}
                >
                  <Outlet />
                </div>
              </Content>
            </Layout>
          </>
        )}
      </Layout>
    </FeatureFlagsProvider>
  );
};
const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
  userAccess: state.auth.userAccess,
});

export default connect(mapStateToProps, {})(MasterLayout);
