// import ProtectedRoute from "app/utils/ProtectedRoute";
import ProtectedRoute from "app/utils/ProtectedRoute";
import Offers from ".";
import OffersList from "./components/OffersList/OffersList";
//eslint-disable-next-line
export default {
  path: "/offers",
  element: <Offers />,
  children: [
    {
      path: "/offers/list",
      element: (
        <ProtectedRoute accessKey="PROMOTER_VIEW">
          <OffersList />
        </ProtectedRoute>
      ),
      exact: true,
    },
  ],
};
