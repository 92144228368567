import {
  CheckOutlined,
  DeleteOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Drawer, Modal, Popconfirm, Switch, Tabs, Tag } from "antd";
import { ACCOUNT_STATUS } from "app/constants/global.constants";
import BrokerDetails from "app/scenes/brokers/components/BorkerDetails";
import InviteBroker from "app/scenes/brokers/components/BrokerList/InviteBroker";
import { apiGet, apiPost } from "app/services/apiService";
import Button from "app/shared/Button";
import CreateInternalUser from "app/shared/InternalUserCreate";
import TabBar from "app/shared/TabBar";
import TableView from "app/shared/TableView";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import Typography from "app/shared/Typography";
import { FeatureFlags } from "app/utils/FeatureFlag/FeatrueFlag";
import { parseURL } from "app/utils/helpers/common.helper";

import { useContext, useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { useSearchParams } from "react-router-dom";
import { API_ENDPOINT_PROJECT_INVESTORS_LIST } from "../../property.constants";
import InvestorDetail from "./components/InvestorDetail";

import "./InvestorList.scss";

const tableColumn = [
  {
    title: "Name",
    index: "user_profile.fullname",
    search: true,
  },

  {
    title: "Mobile",
    index: "user_profile.mobile",
  },

  {
    title: "Total Investment",
    index: "total_investment",
    amount: true,
  },

  {
    title: "Invested Projects",
    index: "total_projects",
    render: (tags) => (
      <>
        <Tag color={tags.total_projects > 0 ? "blue" : "red"}>
          {tags.total_projects || "No "} Projects
        </Tag>
      </>
    ),
  },
];

const actionColumnObj = {
  title: "Action",
  renderAction: true,
  handleAction: (propsData) => propsData.handleAction(propsData),
  actions: [
    {
      event: "view_detail",
      icon: <SearchOutlined style={{ fontSize: 12, color: "white" }} />,
      color: "#69A2FD",
    },
    {
      event: "delete",
      icon: <DeleteOutlined style={{ fontSize: 12, color: "white" }} />,
      color: "#FF0000",
      popup: true,
      popupTitle: "Are you sure want to delete?",
      showOn: (data) => data.hasAccess,
      accessKey: "PROMOTER_MANAGE",
    },
  ],
};
const LIST_TYPE_MAP = {
  1: "ALL",
  2: "APPROVED",
  3: "PENDING",
  4: "REJECTED",
  5: "INVITED",
};

export default function InvestorList(props) {
  const [properties, setProperties] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [showDrawer, setShowDrawer] = useState(false);
  const [investors, setInvestors] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [selectedUser, setSelectedUser] = useState("");
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const { hasAccess } = useContext(FeatureFlags);
  const [searchParams] = useSearchParams();

  useEffect(() => {
    let selectedUser = searchParams.get("selected");
    if (selectedUser && investors.length > 0) {
      // let findUser = investors.find((item) => item.id === selectedUser);
      setSelectedUser(selectedUser);
      setShowModal(true);
    }
  }, [investors]);
  useEffect(() => {
    fetchInvestorList();
  }, [activeTab]);
  const fetchInvestorList = async () => {
    setLoading(true);
    const response = await apiGet(
      API_ENDPOINT_PROJECT_INVESTORS_LIST
    );
    if (response.status) {
      setInvestors(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };
  const handleShowDrawer = (type) => {
    // setShowDrawerFor(type);
    setShowDrawer(true);
  };

  const handleTableAction = (event) => {
    console.log(event);
    switch (event.eventType) {
      case "view_detail":
        setSelectedUser(event.rowData.id);
        setShowModal(true);

        return;
      case "approve":
        handleUserAccountStatus(event.rowData.id, ACCOUNT_STATUS.ACTIVE);
        return;
      case "delete":
        handleUserAccountStatus(event.rowData.id, ACCOUNT_STATUS.DELETED);
      case "toggle":
        handleUserAccountStatus(event.rowData.id, event.rowData.NEW_STATUS);
        return;
      default:
        return null;
    }
  };

  const handleUserAccountStatus = async (userId, status) => {
    const response = await apiPost(
      parseURL("API_ENDPOINT_USER_UPDATE_ACCOUNT_STATUS", {
        userId: userId,
        status: status,
      })
    );
    if (response.status) {
      fetchInvestorList();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
    }
  };

  return (
    <div className="PromoterList">
      <div className="TopSelectors">
        <div className="leftSelectors"></div>
        <div className="rightSelectors">
          {hasAccess("PROMOTER_MANAGE") ? (
            <div className="WidgetContainer" style={{ minWidth: 0 }}>
              <Button onClick={() => setShowDrawer(true)}>
                Create Investors
              </Button>
            </div>
          ) : null}
        </div>
      </div>
      <div id="tabBarContainer">
        <TableView
          data={investors}
          isLoading={isLoading}
          column={[...tableColumn, actionColumnObj]}
          handleAction={handleTableAction}
          hasAccess={hasAccess}
        />
      </div>
      <Modal
        title={"Investor Detail"}
        open={showModal}
        footer={false}
        onCancel={() => setShowModal(false)}
        width={800}
      >
        <InvestorDetail investorId={selectedUser} />
      </Modal>

      <Drawer
        title={"Invite Investors"}
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        width={500}
      >
        <CreateInternalUser
          onCreate={() => {
            setShowDrawer(false);
            fetchInvestorList();
          }}
          userType="Investor"
        />
      </Drawer>
    </div>
  );
}
