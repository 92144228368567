import { Form, Popconfirm } from "antd";
import { apiPost, apiPut } from "app/services/apiService";
import Button from "app/shared/Button";
import Input from "app/shared/InputBox";
import SearchSelector from "app/shared/SearchSelector";
import Selector from "app/shared/Selector";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/helpers/color.helper";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { API_ENDPOINT_SETTINGS_OWNERSHIP_TRANSFER } from "../../settings.constans";

function AppOwnership(props) {
  const [selectedUser, setSelectedUser] = useState(null);
  const dispatch = useDispatch();

  const handleTransferOwnership = async () => {
    const payload = {};
    const response = await apiPost(
      API_ENDPOINT_SETTINGS_OWNERSHIP_TRANSFER + selectedUser
    );
    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
  };
  return (
    <div className="FormContainer">
      <div>
        <Typography>Transfer ownership</Typography>
        <Typography variant="xs" color="gray-dark">
          You can transfer your app ownership to someone incase of leaving
          organisation and for some other reason.
        </Typography>
        <Typography variant="xs" style={{ color: "red" }}>
          Note*{" "}
          <small style={{ color: colorPicker("gray_dark") }}>
            Make sure user is full onboarded with the T&C of ownership of this
            application
          </small>
        </Typography>
      </div>

      <div style={{ marginTop: 24 }}>
        <div style={{ width: 240 }}>
          <SearchSelector
            placeholder="Select User"
            onChange={(item) => setSelectedUser(item)}
          />
        </div>

        <div style={{ width: 160, marginTop: 44 }}>
          <Popconfirm
            title="Transfer ownership confirmation"
            description={`Are you sure want to transfer your admin and app ownership`}
            onConfirm={handleTransferOwnership}
            onCancel={null}
            okText="Yes"
            cancelText="No"
          >
            <Button size="medium" disabled={!selectedUser}>
              Accept & Continue
            </Button>
          </Popconfirm>
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
});

export default connect(mapStateToProps, {})(AppOwnership);
