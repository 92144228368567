// import ProtectedRoute from "app/utils/ProtectedRoute";
import ProtectedRoute from "app/utils/ProtectedRoute";
import Leaves from ".";
import LeavesDashboard from "./components/LeaveDashboard";
//eslint-disable-next-line
export default {
  path: "/leaves",
  element: <Leaves />,
  children: [
    {
      path: "/leaves/dashboard",
      element: (
        <ProtectedRoute accessKey="LEAVE_VIEW">
          <LeavesDashboard />
        </ProtectedRoute>
      ),
      exact: true,
    },
  ],
};
