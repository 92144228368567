import { Area } from "@ant-design/plots";
import { ResponsiveLine } from "@nivo/line";
import { API_ENDPOINT_FETCH_BROKER_ACITIVTY } from "app/scenes/brokers/brokers.constants";
import { apiGet } from "app/services/apiService";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/helpers/color.helper";
import { parseURL } from "app/utils/helpers/common.helper";
import moment from "moment";
import { useEffect, useState } from "react";
const data = [
  {
    id: "japan",
    color: "hsl(47, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 99,
      },
      {
        x: "helicopter",
        y: 285,
      },
      {
        x: "boat",
        y: 269,
      },
      {
        x: "train",
        y: 35,
      },
      {
        x: "subway",
        y: 206,
      },
      {
        x: "bus",
        y: 142,
      },
      {
        x: "car",
        y: 188,
      },
      {
        x: "moto",
        y: 254,
      },
      {
        x: "bicycle",
        y: 286,
      },
      {
        x: "horse",
        y: 251,
      },
      {
        x: "skateboard",
        y: 197,
      },
      {
        x: "others",
        y: 221,
      },
    ],
  },
  {
    id: "france",
    color: "hsl(239, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 229,
      },
      {
        x: "helicopter",
        y: 106,
      },
      {
        x: "boat",
        y: 291,
      },
      {
        x: "train",
        y: 267,
      },
      {
        x: "subway",
        y: 39,
      },
      {
        x: "bus",
        y: 209,
      },
      {
        x: "car",
        y: 299,
      },
      {
        x: "moto",
        y: 83,
      },
      {
        x: "bicycle",
        y: 300,
      },
      {
        x: "horse",
        y: 139,
      },
      {
        x: "skateboard",
        y: 195,
      },
      {
        x: "others",
        y: 175,
      },
    ],
  },
  {
    id: "germany",
    color: "hsl(253, 70%, 50%)",
    data: [
      {
        x: "plane",
        y: 154,
      },
      {
        x: "helicopter",
        y: 70,
      },
      {
        x: "boat",
        y: 108,
      },
      {
        x: "train",
        y: 286,
      },
      {
        x: "subway",
        y: 300,
      },
      {
        x: "bus",
        y: 57,
      },
      {
        x: "car",
        y: 116,
      },
      {
        x: "moto",
        y: 229,
      },
      {
        x: "bicycle",
        y: 172,
      },
      {
        x: "horse",
        y: 18,
      },
      {
        x: "skateboard",
        y: 128,
      },
      {
        x: "others",
        y: 181,
      },
    ],
  },
];

export default function BrokerActivity(props) {
  const [activity, setActivity] = useState([]);
  useEffect(() => {
    fetchActivityData();
  }, []);
  const fetchActivityData = async () => {
    const response = await apiGet(
      parseURL(API_ENDPOINT_FETCH_BROKER_ACITIVTY, {
        brokerId: props.brokerId,
      }) + "?start=2024-01-14T16:04:35.000Z&end=2024-01-14T16:04:35.000Z"
    );
    if (response.status) {
      setActivity(response.data);
    }
  };
  const config = {
    data,
    xField: "Date",
    yField: "scales",
    xAxis: {
      range: [0, 1],
      tickCount: 5,
    },
    yAxis: {
      tickCount: 5,
    },
  };

  const processData = (lineData) => {
    let salesResp = {
      id: "Sales",
      data: [],
    };
    let visitResp = {
      id: "Visits",
      data: [],
    };
    let bookingResp = {
      id: "Bookings",
      data: [],
    };
    lineData.sales &&
      lineData.sales.forEach((sale) => {
        salesResp.data.push({
          x: moment(sale.date).format("YYYY-MM-DD"),
          y: sale.score,
        });
      });
    lineData.visits &&
      lineData.visits.forEach((visit) => {
        visitResp.data.push({
          x: moment(visit.date).format("YYYY-MM-DD"),
          y: visit.score,
        });
      });

    lineData.bookings &&
      lineData.bookings.forEach((booking) => {
        bookingResp.data.push({
          x: moment(booking.date).format("YYYY-MM-DD"),
          y: booking.score,
        });
      });
    console.log([salesResp]);
    return [salesResp, visitResp, bookingResp];
  };
  return (
    <div
      className="SalesOverview"
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        borderRadius: 16,
        padding: 16,
      }}
    >
      <div>
        <Typography variant="h5">Broker Activity</Typography>
      </div>
      <div style={{ height: "80%" }}>
        <ResponsiveLine
          data={processData(activity)}
          margin={{ top: 40, right: 20, bottom: 30, left: 30 }}
          yScale={{
            type: "linear",
            min: "0",
            max: "auto",
          }}
          xFormat="time:%Y-%m-%d"
          curve="monotoneX"
          axisTop={null}
          colors={[
            colorPicker("green"),
            colorPicker("red"),
            colorPicker("yellow"),
          ]}
          xScale={{
            type: "time",
            format: "%Y-%m-%d",
            useUTC: false,
            precision: "day",
          }}
          axisRight={null}
          axisBottom={{
            tickSize: 5,
            tickPadding: 5,
            tickRotation: 0,
            legendOffset: 36,
            legendPosition: "middle",
            format: "%b %d",
          }}
          defs={[
            {
              colors: [
                {
                  color: "inherit",
                  offset: 0,
                },
                {
                  color: "inherit",
                  offset: 100,
                  opacity: 0,
                },
              ],
              id: "gradientA",
              type: "linearGradient",
            },
          ]}
          axisLeft={null}
          enableGridX={false}
          enableGridY={false}
          enablePoints={false}
          pointSize={10}
          pointBorderWidth={2}
          enableArea={true}
          enableCrosshair={false}
          lineWidth={3}
          useMesh={true}
          fill={[
            {
              id: "gradientA",
              match: "*",
            },
          ]}
          legends={[]}
          tooltip={({ point }) => {
            return (
              <div
                style={{
                  backgroundColor: "white",
                  padding: 6,
                  borderRadius: 4,
                  boxShadow: "rgba(149, 157, 165, 0.2) 0px 8px 24px",
                }}
              >
                <div>
                  <Typography variant="caption">
                    {point.id.split(".")[0]} as of{" "}
                    <b>{moment(point?.data?.xFormatted).format("MMM Do")} </b>
                  </Typography>
                </div>
                <div style={{ marginTop: "10px" }}>
                  <div className="Info">
                    <Typography>{point.data.y}</Typography>
                  </div>
                </div>
              </div>
            );
          }}
        />
      </div>
    </div>
  );
}
