import { colorPicker } from "app/utils/helpers/color.helper";
import React from "react";
import "./OTPBox.scss";
class Otpinput extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      value: "",
      otp1: "",
      otp2: "",
      otp3: "",
      otp4: "",
      otp5: "",
      otp6: "",
      mainOTP: "",
      disable: true,
      focused: null,
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleChange(value1, event) {
    this.setState({
      [value1]: event.target.value,
    });
    this.props.onChange && this.props.onChange(event.target.value);
  }

  handleSubmit(event) {
    // const data = new FormData(event.target);
    // console.log(this.state);
    // event.preventDefault();
  }

  inputfocus = (elmnt) => {
    if (elmnt.key === "Delete" || elmnt.key === "Backspace") {
      const next = elmnt.target.tabIndex - 2;
      if (next > -1) {
        elmnt.target.form.elements[next].focus();
      }
    } else {
      const next = elmnt.target.tabIndex;
      if (next < 6) {
        elmnt.target.form.elements[next].focus();
      } else {
        const payload =
          this.state.otp1 +
          this.state.otp2 +
          this.state.otp3 +
          this.state.otp4 +
          this.state.otp5 +
          this.state.otp6;
      }
    }
  };

  render() {
    return (
      <form>
        <div className="OTPBox">
          <input
            name="otp1"
            type="text"
            autoComplete="off"
            className="otp-input"
            value={this.state.otp1}
            onKeyPress={this.keyPressed}
            onChange={(e) => this.handleChange("otp1", e)}
            tabIndex="1"
            maxLength="1"
            onKeyUp={(e) => this.inputfocus(e)}
            style={{
              backgroundColor: colorPicker(
                this.state.otp1 ? "green" : "gray_light"
              ),
              color: this.state.otp1 ? "white" : "black",
            }}
          />
          <input
            name="otp2"
            type="text"
            autoComplete="off"
            className="otp-input"
            value={this.state.otp2}
            onChange={(e) => this.handleChange("otp2", e)}
            tabIndex="2"
            maxLength="1"
            onKeyUp={(e) => this.inputfocus(e)}
            style={{
              backgroundColor: colorPicker(
                this.state.otp2 ? "green" : "gray_light"
              ),
              color: this.state.otp2 ? "white" : "black",
            }}
          />
          <input
            name="otp3"
            type="text"
            autoComplete="off"
            className="otp-input"
            value={this.state.otp3}
            onChange={(e) => this.handleChange("otp3", e)}
            tabIndex="3"
            maxLength="1"
            onKeyUp={(e) => this.inputfocus(e)}
            style={{
              backgroundColor: colorPicker(
                this.state.otp3 ? "green" : "gray_light"
              ),
              color: this.state.otp3 ? "white" : "black",
            }}
          />
          <input
            name="otp4"
            type="text"
            autoComplete="off"
            className="otp-input"
            value={this.state.otp4}
            onChange={(e) => this.handleChange("otp4", e)}
            tabIndex="4"
            maxLength="1"
            onKeyUp={(e) => this.inputfocus(e)}
            style={{
              backgroundColor: colorPicker(
                this.state.otp4 ? "green" : "gray_light"
              ),
              color: this.state.otp4 ? "white" : "black",
            }}
          />

          <input
            name="otp5"
            type="text"
            autoComplete="off"
            className="otp-input"
            value={this.state.otp5}
            onChange={(e) => this.handleChange("otp5", e)}
            tabIndex="5"
            maxLength="1"
            onKeyUp={(e) => this.inputfocus(e)}
            style={{
              backgroundColor: colorPicker(
                this.state.otp5 ? "green" : "gray_light"
              ),
              color: this.state.otp5 ? "white" : "black",
            }}
          />
          <input
            name="otp6"
            type="text"
            autoComplete="off"
            className="otp-input"
            value={this.state.otp6}
            onChange={(e) => this.handleChange("otp6", e)}
            tabIndex="6"
            maxLength="1"
            onKeyUp={(e) => this.inputfocus(e)}
            style={{
              backgroundColor: colorPicker(
                this.state.otp6 ? "green" : "gray_light"
              ),
              color: this.state.otp6 ? "white" : "black",
            }}
          />
        </div>
      </form>
    );
  }
}

export default Otpinput;
