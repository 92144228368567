import { Form } from "antd";
import Button from "app/shared/Button";
import Input from "app/shared/InputBox";
import Typography from "app/shared/Typography";
import { useState } from "react";
import { CheckCircleOutlined } from "@ant-design/icons";

import "../../styles/auth.scss";
import { useNavigate, useParams } from "react-router";
import { apiPost } from "app/services/apiService";
import { API_ENDPOINT_AUTH_CHANGE_PASSWORD } from "../../auth.constants";
import { connect, useDispatch } from "react-redux";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import { colorPicker } from "app/utils/helpers/color.helper";

function ChangePassword(props) {
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [sceneTab, setSceneTab] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  let { resetToken } = useParams();

  const ChangePassword = async () => {
    setLoading(true);
    if (confirmPassword === password) {
      const payload = {
        verification_code: resetToken,
        password: password,
        password_confirm: confirmPassword,
      };
      const response = await apiPost(
        API_ENDPOINT_AUTH_CHANGE_PASSWORD,
        payload
      );
      if (response.status) {
        dispatch({
          type: ADD_TOAST_MESSAGE,
          payload: { message: response.message, type: 1 },
        });
        setSceneTab(1);
      } else {
        dispatch({
          type: ADD_TOAST_MESSAGE,
          payload: { message: response.message, type: 0 },
        });
      }
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: "Passwords do not match", type: 0 },
      });
    }
    setLoading(false);
  };

  return sceneTab === 0 ? (
    <Form
      name="basic"
      initialValues={{ remember: true }}
      onFinish={ChangePassword}
      autoComplete="off"
    >
      <Typography style={{ textAlign: "center" }} variant="h5">
        Change Password
      </Typography>
      <div className="input-element" style={{ marginBottom: 24 }}>
        <Form.Item
          name="password"
          rules={[
            {
              required: true,
              message: "Please enter your password!",
            },
          ]}
        >
          <Input
            placeholder="Password"
            type="password"
            label="Password"
            onChange={(e) => setPassword(e.target.value)}
          />
        </Form.Item>
      </div>
      <div className="input-element" style={{ marginBottom: 24 }}>
        <Form.Item
          name="passwordconfirm"
          rules={[
            {
              required: true,
              message: "Please enter your confirm password!",
            },
          ]}
        >
          <Input
            placeholder="Confirm Password"
            type="password"
            label="Confirm Password"
            onChange={(e) => setConfirmPassword(e.target.value)}
          />
        </Form.Item>
      </div>
      <div className="action-buttons">
        <Form.Item style={{ width: "100%" }}>
          <Button
            type="primary"
            size="large"
            style={{ width: "100%" }}
            htmlType="submit"
            loading={isLoading}
            // onClick={() => setSceneTab(1)}
          >
            Change Password
          </Button>
        </Form.Item>
      </div>
    </Form>
  ) : (
    <div>
      <Typography style={{ textAlign: "center" }} variant="h5">
        <CheckCircleOutlined style={{ color: colorPicker("red") }} /> Wohoo!
        your password has been succesfully changed
      </Typography>

      <Button
        size="large"
        style={{ width: "100%" }}
        type="link"
        danger
        onClick={() => navigate("/auth/authenticate")}
      >
        Sign in with your new password
      </Button>
    </div>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
});

export default connect(mapStateToProps, {})(ChangePassword);
