import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Form, message, Upload } from "antd";
import Button from "app/shared/Button";
import Input from "app/shared/InputBox";
import Selector from "app/shared/Selector";
import Typography from "app/shared/Typography";
import { useState } from "react";
import "./AddFacility.scss";
import Papa from "papaparse";
import { apiGet, apiPost } from "app/services/apiService";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import {
  API_ENDPOINT_GLOBAL_ADD_AMENTITY,
  API_ENDPOINT_GLOBAL_ADD_FACILITY,
} from "app/scenes/property/property.constants";
export default function AddFacility(props) {
  const [amentityList, setAmentityList] = useState([
    {
      id: 1,
      name: "",
      description: "",
    },
  ]);
  const [isLoading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const addRowInvite = () => {
    const currentId = amentityList[amentityList.length - 1].id;
    setAmentityList([
      ...amentityList,
      { name: "", description: "", id: currentId + 1 },
    ]);
  };

  const removeRowInvite = (itemId) => {
    setAmentityList(amentityList.filter((item) => item.id !== itemId));
  };

  const handleAddAmentity = async () => {
    setLoading(true);
    const response = await apiPost(API_ENDPOINT_GLOBAL_ADD_FACILITY, {
      facilites: amentityList,
    });
    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
      props.onCreate && props.onCreate();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };

  const handleInput = (rowId, key, data) => {
    let prevData = JSON.parse(JSON.stringify(amentityList));
    prevData[rowId][key] = data;
    setAmentityList(prevData);
  };

  const beforeUpload = (file) => {
    Papa.parse(file, {
      skipEmptyLines: true,
      complete: function (results) {
        addParsedEntry(results.data);
      },
    });
  };

  const addParsedEntry = (entries) => {
    let newEntries = [];
    let newId = amentityList.length + 1;
    entries.forEach((element) => {
      newEntries.push({
        name: element[0],
        description: element[1],
        id: newId,
      });
      newId = newId + 1;
    });
    setAmentityList(newEntries);
  };
  console.log("amentity", amentityList);
  return (
    <div className="AddFacility">
      <Form
        name="basic"
        initialValues={{ remember: true, amentityList }}
        onFinish={handleAddAmentity}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div className="formContainer">
          {amentityList.map((amentity, key) => {
            return (
              <div className="form-row" key={key}>
                <div className="input-element">
                  <Form.Item
                    name={"name" + amentity.id}
                    rules={[
                      {
                        required: true,
                        message: "Please check your input",
                      },
                    ]}
                    style={{ marginBottom: 0 }}
                    initialValue={amentity.name}
                  >
                    <Input
                      placeholder="Name"
                      name={"name" + amentity.id}
                      onChange={(e) => handleInput(key, "name", e.target.value)}
                      defaultValue={amentity.name}
                    />
                  </Form.Item>
                </div>
                <div className="input-element">
                  <Form.Item
                    name={"description" + amentity.id}
                    style={{ marginBottom: 0 }}
                  >
                    <Input
                      placeholder="Description"
                      name={"name" + amentity.id}
                      onChange={(e) =>
                        handleInput(key, "description", e.target.value)
                      }
                      defaultValue={amentity.description}
                    />
                  </Form.Item>
                </div>

                <PlusCircleOutlined onClick={addRowInvite} />
                <DeleteOutlined onClick={() => removeRowInvite(amentity.id)} />
              </div>
            );
          })}
        </div>
        <Upload
          {...props}
          accept=".csv"
          beforeUpload={beforeUpload}
          customRequest={null}
          action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
        >
          <Typography
            variant="xs"
            color="primary"
            style={{ cursor: "pointer" }}
          >
            Add Bulk Facility
          </Typography>
        </Upload>

        <div className="action-buttons">
          <Form.Item style={{ width: 240 }}>
            <Button
              type="primary"
              size="large"
              style={{ width: "100%" }}
              htmlType="submit"
              loading={isLoading}
            >
              Create
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}
