import React, { useState } from "react";
import { InboxOutlined, PlusOutlined } from "@ant-design/icons";
import { message, Modal, Upload } from "antd";
import { apiDelete, apiGet, apiPostForm } from "app/services/apiService";
import { parseURL } from "app/utils/helpers/common.helper";
import {
  API_ENDPOINT_PROPERTY_DELETE_IMAGE,
  API_ENDPOINT_PROPERTY_FETCH_IMAGES,
  API_ENDPOINT_PROPERTY_UPDATE_IMAGE,
} from "app/scenes/property/property.constants";
import "./modifier.scss";
import { useEffect } from "react";
import Button from "app/shared/Button";
import { useDispatch } from "react-redux";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import { useNavigate, useParams } from "react-router";
import Dragger from "antd/es/upload/Dragger";
const getBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = (error) => reject(error);
  });
const PropertyImages = (props) => {
  const [previewOpen, setPreviewOpen] = useState(false);
  const [previewImage, setPreviewImage] = useState("");
  const [previewTitle, setPreviewTitle] = useState("");
  const [fileList, setFileList] = useState([]);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  useEffect(() => {
    if (props.editMode) fetchPropertyImages();
  }, []);
  const fetchPropertyImages = async () => {
    const response = await apiGet(
      parseURL(API_ENDPOINT_PROPERTY_FETCH_IMAGES, {
        propertyId: props.propertyId,
      })
    );
    if (response.status) {
      setFileList(response.data);
    }
  };
  const handleCancel = () => setPreviewOpen(false);
  const handlePreview = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }
    setPreviewImage(file.url || file.preview);
    setPreviewOpen(true);
    setPreviewTitle(
      file.name || file.url.substring(file.url.lastIndexOf("/") + 1)
    );
  };
  const handleChange = ({ fileList: newFileList }) => setFileList(newFileList);
  const uploadButton = (
    <div>
      <PlusOutlined />
      <div
        style={{
          marginTop: 8,
        }}
      >
        Upload
      </div>
    </div>
  );

  const beforeUpload = (file) => {
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 5;
    if (!isLt2M) {
      message.error("Image must smaller than 5MB!");
    }
    return isJpgOrPng && isLt2M;
  };

  const addImageToProperty = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    console.log("image ", file);
    let formData = new FormData();
    formData.append("file", file);
    const response = await apiPostForm(
      parseURL(API_ENDPOINT_PROPERTY_UPDATE_IMAGE, {
        propertyId: props.propertyId,
      }),
      formData
    );
    if (response.status) {
      onSuccess("Ok");
    } else {
      const error = new Error("Some error");
      onError({ error });
    }
  };

  const deleteImageFromProperty = async (image) => {
    const response = await apiDelete(
      parseURL(API_ENDPOINT_PROPERTY_DELETE_IMAGE, {
        propertyId: props.propertyId,
        imageId: image.id,
      })
    );
    if (response.status) {
    } else {
    }
  };

  const handleFinishCreate = () => {
    dispatch({
      type: ADD_TOAST_MESSAGE,
      payload: {
        message: props.editMode
          ? "Property details updated"
          : "Created property submitted for approval",
        type: 1,
      },
    });
    navigate("/property/list");
  };

  const dragProps = {
    name: "file",
    multiple: true,
    listType: "picture-card",
    action: "https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188",
    onChange(info) {
      const { status } = info.file;
      if (status !== "uploading") {
        console.log(info.file, info.fileList);
      }
      if (status === "done") {
        message.success(`${info.file.name} file uploaded successfully.`);
      } else if (status === "error") {
        message.error(`${info.file.name} file upload failed.`);
      }
    },
    onDrop(e) {
      console.log("Dropped files", e.dataTransfer.files);
    },
  };

  return (
    <div
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "column",
        justifyContent: "space-between",
        height: "100%",
      }}
    >
      <div
        className="PropertyPhotoUploader"
        style={{
          width: "100%",
          display: "flex",
          background: "white",
          padding: 12,
          borderRadius: 16,
        }}
      >
        {/* <Upload
          listType="picture-card"
          fileList={fileList}
          customRequest={addImageToProperty}
          onPreview={handlePreview}
          beforeUpload={beforeUpload}
          onChange={handleChange}
          onRemove={deleteImageFromProperty}
          style={{ width: "auto", height: 88 }}
          maxCount={25}
          multiple
          // action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
        >
          {uploadButton}
        </Upload> */}

        <Dragger
          {...dragProps}
          fileList={fileList}
          height={200}
          onChange={handleChange}
          onRemove={deleteImageFromProperty}
          customRequest={addImageToProperty}
        >
          <p className="ant-upload-drag-icon">
            <InboxOutlined />
          </p>
          <p className="ant-upload-text">
            Click or drag file to this area to upload
          </p>
          <p className="ant-upload-hint">Maximum file size upload: 10 MB</p>
        </Dragger>
      </div>
      <div className="FormAction" style={{ marginTop: 24 }}>
        {props.goBack ? (
          <div style={{ marginRight: 24 }}>
            <Button
              style={{ width: 360, marginRight: 24 }}
              // onClick={() => setShowImageDialog(true)}
              background={"black"}
              onClick={props.goBack}
            >
              Back
            </Button>
          </div>
        ) : null}
        <Button
          style={{ width: 360 }}
          onClick={handleFinishCreate}
          htmlType="submit"
          // loading={isLoading}
        >
          Finish
        </Button>
      </div>
      <Modal
        open={previewOpen}
        title={previewTitle}
        footer={null}
        onCancel={handleCancel}
      >
        <img
          alt="example"
          style={{
            width: "100%",
          }}
          src={previewImage}
        />
      </Modal>
    </div>
  );
};
export default PropertyImages;
