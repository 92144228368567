import {
  CheckOutlined,
  CloseCircleOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { ConfigProvider, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import { API_ENDPOINT_LEAVE_APPROVE_REJECT_LEAVE } from "app/scenes/leaves/leaves.constants";
import { apiPatch, apiPost } from "app/services/apiService";
import TableView from "app/shared/TableView";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import { parseURL } from "app/utils/helpers/common.helper";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

const columns = [
  {
    title: "Type",
    index: "type",
    key: "type",
    renderTag: true,
    tagColorMap: { PAID: "green", UNPAID: "red" },
  },
  {
    title: "Employee",
    index: "applicant.user_profile.fullname",
  },
  {
    title: "From",
    index: "from",
    key: "from",
    date: true,
  },
  {
    title: "To",
    index: "to",
    key: "to",
    date: true,
  },
  {
    title: "No of Days",
    index: "days",
    key: "days",
  },
  {
    title: "Leave Balance",
    index: "balance",
    key: "balance",
  },
  //   {
  //     title: "Status",
  //     index: "status",
  //     key: "status",
  //     renderTag: true,
  //     tagColorMap: {
  //       APPROVED: "green",
  //       CANCELLED: "red",
  //       REJECTED: "red",
  //       PENDING: "yellow",
  //     },
  //   },
  {
    title: "Description",
    index: "description",
    key: "days",
  },
];
const actionColumnObj = {
  title: "Action",
  renderAction: true,
  handleAction: (propsData) => propsData.handleAction(propsData),
  actions: [
    {
      event: "approve",
      icon: <CheckOutlined style={{ fontSize: 12, color: "white" }} />,
      color: "#73AF00",
      popup: true,
      popupTitle: "Are you sure want to approve?",
    },
    {
      event: "reject",
      icon: <CloseOutlined style={{ fontSize: 12, color: "white" }} />,
      color: "#FF0000",
    },
  ],
};

export default function LeaveRequests(props) {
  const [isLoading, setLoading] = useState(false);
  const [comment, setComment] = useState("");
  const [showModal, setShowModal] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const dispatch = useDispatch();
  const handleTableAction = (event) => {
    if (event.eventType === "approve") {
      approveRejectLeave(event.rowData, "APPROVED");
    } else {
      //   approveRejectLeave(event.rowData, "REJECTED");
      setSelectedRow(event.rowData);
      setShowModal(true);
    }
  };

  const approveRejectLeave = async (rowInfo, statusId) => {
    console.log("rowId:", rowInfo);
    const response = await apiPost(
      parseURL(API_ENDPOINT_LEAVE_APPROVE_REJECT_LEAVE, {
        userId: rowInfo.applicantId,
        leaveId: rowInfo.id,
      }),
      {
        comment: comment,
        status: statusId,
      }
    );
    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
      setShowModal(false);
      props.onSuccess && props.onSuccess();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
  };

  return (
    <ConfigProvider
      theme={{
        components: {
          Table: {
            headerBorderRadius: 0,
          },
        },
      }}
    >
      <div style={{ marginTop: 24 }}>
        <TableView
          column={[...columns, actionColumnObj]}
          data={props.data}
          isLoading={isLoading}
          size="small"
          handleAction={handleTableAction}
        />
      </div>
      <Modal
        title="Why are you rejecting it?"
        open={showModal}
        onOk={() => approveRejectLeave(selectedRow, "REJECTED")}
        onCancel={() => setShowModal(false)}
      >
        <div className="" style={{ marginTop: 24, marginBottom: 24 }}>
          <TextArea
            //   value={value}
            //   onChange={(e) => setValue(e.target.value)}
            placeholder="Please add your comment here...."
            style={{
              backgroundColor: "#F4F6F9",
              width: "100%",
              border: "none",
              borderRadius: 12,
            }}
            onChange={(e) => setComment(e.target.value)}
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </div>
      </Modal>
    </ConfigProvider>
  );
}
