import COLORS from "theme/color";
export const rootHexToRgba = (rootHex, opacity) => {
  console.log("root hex:", rootHex);
  let hex = window
    .getComputedStyle(document.documentElement)
    .getPropertyValue(rootHex);
  hex = hex.replace(/ /g, "");
  let c;
  if (/^#([A-Fa-f0-9]{3}){1,2}$/.test(hex)) {
    c = hex.substring(1).split("");
    if (c.length === 3) {
      c = [c[0], c[0], c[1], c[1], c[2], c[2]];
    }
    c = "0x" + c.join("");
    return (
      "rgba(" +
      [(c >> 16) & 255, (c >> 8) & 255, c & 255].join(",") +
      "," +
      opacity +
      ")"
    );
  }
  throw new Error("Bad Hex");
};

export const colorPicker = (colorname) => {
  return COLORS[colorname] || "#000";
};
