import { useRoutes } from "react-router-dom";

import { useDispatch } from "react-redux";
import RouteMap from "app/configs/RouteConfig";
import { useEffect, useState } from "react";
import { API_ENDPOINT_DASHBOARD_HUDDLE } from "app/scenes/dashboard/dashboard.constants";
import { apiGet } from "app/services/apiService";
import { SAVE_USER_ACCESS_INFO } from "app/scenes/auth/redux/auth.action";
import "theme/index.scss";

const Entry = () => {
  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch();
  // console.log("env value",API_ENDPOINT_DASHBOARD_HUDDLE)
  useEffect(() => {
    fetchDependencies();
  }, []);

  const fetchDependencies = async () => {
    setLoading(true);
    const response = await apiGet(
      API_ENDPOINT_DASHBOARD_HUDDLE
    );
    if (response.status) {
      dispatch({
        type: SAVE_USER_ACCESS_INFO,
        payload: response.data.feature_access,
      });
    } else {
      dispatch({
        type: SAVE_USER_ACCESS_INFO,
        payload: [],
      });
    }
    setLoading(false);
  };

  const routes = useRoutes([...RouteMap]);
  return routes;
};

export default Entry;
