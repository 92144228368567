import {
  CheckOutlined,
  DeleteOutlined,
  DownloadOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { Drawer, Modal, Popconfirm, Switch, Tabs } from "antd";
import { ACCOUNT_STATUS } from "app/constants/global.constants";
import BrokerDetails from "app/scenes/brokers/components/BorkerDetails";
import InviteBroker from "app/scenes/brokers/components/BrokerList/InviteBroker";
import { apiGet, apiPost } from "app/services/apiService";
import Button from "app/shared/Button";
import CreateInternalUser from "app/shared/InternalUserCreate";
import TabBar from "app/shared/TabBar";
import TableView from "app/shared/TableView";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import Typography from "app/shared/Typography";
import { FeatureFlags } from "app/utils/FeatureFlag/FeatrueFlag";
import { parseURL } from "app/utils/helpers/common.helper";

import { useContext, useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import { API_ENDPOINT_REPORT_LIST } from "../../reports.constants";
import CreateReport from "../CreateReport";
import "./reportlist.scss";

const tableColumn = [
  {
    title: "Report Name",
    index: "name",
    search: true,
  },

  {
    title: "Type",
    index: "type",
    renderTag: true,
  },
  {
    title: "From",
    index: "from",
    date: true,
  },
  {
    title: "To",
    index: "to",
    date: true,
  },

  {
    title: "Created On",
    index: "createdAt",
    date: true,
  },

  {
    title: "Created By",
    index: "user.user_profile.fullname",
  },

  {
    title: "Status",
    index: "status",
    renderTag: true,
    tagColorMap: {
      SUCCESS: "green",
      PENDING: "yellow",
      FAILED: "red",
    },
  },
];

const actionColumnObj = {
  title: "Action",
  renderAction: true,
  handleAction: (propsData) => propsData.handleAction(propsData),
  actions: [
    {
      event: "download",
      icon: <DownloadOutlined style={{ fontSize: 12, color: "white" }} />,
      color: "#69A2FD",
    },
    {
      event: "delete",
      icon: <DeleteOutlined style={{ fontSize: 12, color: "white" }} />,
      color: "#FF0000",
      popup: true,
      popupTitle: "Are you sure want to delete?",
      showOn: (data) => data.hasAccess,
      accessKey: "EMPLOYEE_MANAGE",
    },
  ],
};
const LIST_TYPE_MAP = {
  1: "ALL",
  2: "APPROVED",
  3: "PENDING",
  4: "REJECTED",
  5: "INVITED",
};

export default function ReportList(props) {
  const [properties, setProperties] = useState([]);
  const [isLoading, setLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const [showDrawer, setShowDrawer] = useState(false);
  const [brokers, setBrokers] = useState([]);
  const [activeTab, setActiveTab] = useState(1);
  const [showDrawerFor, setShowDrawerFor] = useState("");
  const [selectedUser, setSelectedUser] = useState("");
  const dispatch = useDispatch();
  const { hasAccess } = useContext(FeatureFlags);

  useEffect(() => {
    fetchBrokerList();
  }, [activeTab]);
  const fetchBrokerList = async () => {
    setLoading(true);
    const response = await apiGet(
      API_ENDPOINT_REPORT_LIST
    );
    if (response.status) {
      setBrokers(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };
  const handleShowDrawer = (type) => {
    setShowDrawerFor(type);
    setShowDrawer(true);
  };

  const handleTableAction = (event) => {
    console.log(event);
    switch (event.eventType) {
      case "download":
        window.open(event.rowData.download_link, "_blank");
        return;
      case "approve":
        handleUserAccountStatus(event.rowData.id, ACCOUNT_STATUS.ACTIVE);
        return;
      case "delete":
        handleUserAccountStatus(event.rowData.id, ACCOUNT_STATUS.DELETED);
      case "toggle":
        handleUserAccountStatus(event.rowData.id, event.rowData.NEW_STATUS);
        return;
      default:
        return null;
    }
  };

  const handleUserAccountStatus = async (userId, status) => {
    const response = await apiPost(
      parseURL("API_ENDPOINT_USER_UPDATE_ACCOUNT_STATUS", {
        userId: userId,
        status: status,
      })
    );
    if (response.status) {
      fetchBrokerList();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
    }
  };

  return (
    <div className="ReportList">
      <div className="TopSelectors">
        <div className="leftSelectors"></div>
        <div className="rightSelectors">
          {hasAccess("EMPLOYEE_MANAGE") ? (
            <div className="WidgetContainer" style={{ minWidth: 0 }}>
              <Button onClick={() => setShowModal(true)}>Create</Button>
            </div>
          ) : null}
        </div>
      </div>

      <div id="tabBarContainer">
        <TableView
          data={brokers}
          isLoading={isLoading}
          column={[...tableColumn, actionColumnObj]}
          handleAction={handleTableAction}
          hasAccess={hasAccess}
        />
      </div>
      <Drawer
        title={
          showDrawerFor === "INVITE" ? "Create Employee" : "Promoter Details"
        }
        open={showDrawer}
        onClose={() => setShowDrawer(false)}
        width={showDrawerFor === "INVITE" ? 400 : 600}
      >
        {showDrawerFor === "INVITE" ? (
          <CreateInternalUser
            onCreate={() => {
              setShowDrawer(false);
              fetchBrokerList();
            }}
            userType="Employee"
          />
        ) : (
          <BrokerDetails selectedUser={selectedUser} userType="Employee" />
        )}
      </Drawer>

      <Modal
        title={"Create Report"}
        open={showModal}
        footer={false}
        onCancel={() => setShowModal(false)}
        width={800}
      >
        <CreateReport
          onCreate={() => {
            setShowModal(false);
            fetchBrokerList();
          }}
        />
      </Modal>
    </div>
  );
}
