import React, { useContext, useState } from "react";
import "./settings.scss";
import PersonIcon from "@mui/icons-material/Person";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/helpers/color.helper";
import ShieldIcon from "@mui/icons-material/Shield";
import ToggleOffIcon from "@mui/icons-material/ToggleOff";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import UserProfile from "./components/UserProfile";
import RolesAccess from "./components/RolesAccess";
import { FeatureFlags } from "app/utils/FeatureFlag/FeatrueFlag";
import AppOwnership from "./components/AppOwnership";
const HEADER_NAME = ["Profile", "Roles & Access", "", "App Ownership"];
export default function Settings(props) {
  const [activeTab, setActiveTab] = useState(0);
  const { hasAccess } = useContext(FeatureFlags);

  const renderScene = () => {
    switch (activeTab) {
      case 0:
        return <UserProfile />;
      case 1:
        return <RolesAccess />;
      case 3:
        return <AppOwnership />;
      default:
        return <RolesAccess />;
    }
  };

  return (
    <div className="Settings">
      <div className="SettingDrawer">
        <div className="MenuList">
          <div
            className={"MenuItem " + (activeTab === 0 ? "--selected" : "")}
            onClick={() => setActiveTab(0)}
          >
            <PersonIcon
              style={{ color: colorPicker("gray_dark"), marginRight: 8 }}
            />
            <Typography variant="caption">Profile</Typography>
          </div>

          {hasAccess("SETTING_MANAGE") ? (
            <>
              <div
                className={"MenuItem " + (activeTab === 1 ? "--selected" : "")}
                onClick={() => setActiveTab(1)}
              >
                <ShieldIcon
                  style={{ color: colorPicker("gray_dark"), marginRight: 8 }}
                />
                <Typography variant="caption">Roles & Access</Typography>
              </div>
              {/* <div className="MenuItem">
                <ToggleOffIcon
                  style={{ color: colorPicker("gray_dark"), marginRight: 8 }}
                />
                <Typography variant="caption">App Configuration</Typography>
              </div> */}
              <div
                className={"MenuItem " + (activeTab === 3 ? "--selected" : "")}
                onClick={() => setActiveTab(3)}
              >
                <AdminPanelSettingsIcon
                  style={{ color: colorPicker("gray_dark"), marginRight: 8 }}
                />
                <Typography variant="caption">Ownership</Typography>
              </div>
            </>
          ) : null}
        </div>
      </div>
      <div className="SettingContent">
        <div className="ContentHeader">
          <Typography variant="h6">{HEADER_NAME[activeTab]}</Typography>
        </div>
        <div className="ContentBody">{renderScene()}</div>
      </div>
    </div>
  );
}
