// import ProtectedRoute from "app/utils/ProtectedRoute";
import ProtectedRoute from "app/utils/ProtectedRoute";
import Brokers from ".";
import BrokerDashboard from "./components/BrokerDashboard";
import BrokerDashboardComission from "./components/BrokerDashboardComission";
import BrokerDashboardSales from "./components/BrokerDashboardSales";
import BrokerDashboardVisit from "./components/BrokerDashboardVisit";
import BrokerList from "./components/BrokerList";
import PendingComissionList from "./components/BrokerList/PendingComissionList";
import TestPayment from "./components/TestPayment";
//eslint-disable-next-line
export default {
  path: "/brokers",
  element: <Brokers />,
  children: [
    {
      path: "/brokers/list",
      element: (
        <ProtectedRoute accessKey="BROKER_VIEW">
          <BrokerList />
        </ProtectedRoute>
      ),
      exact: true,
    },
    {
      path: "/brokers/payment",
      element: (
        <ProtectedRoute accessKey="PROPERTY_LISTING_VIEW">
          <TestPayment />
        </ProtectedRoute>
      ),
      exact: true,
    },
    {
      path: "/brokers/:brokerId/dashboard",
      element: (
        <ProtectedRoute accessKey="BROKER_VIEW">
          <BrokerDashboard />
        </ProtectedRoute>
      ),
      exact: true,
    },
    {
      path: "/brokers/:brokerId/comission",
      element: (
        <ProtectedRoute accessKey="BROKER_VIEW">
          <BrokerDashboardComission />
        </ProtectedRoute>
      ),
      exact: true,
    },
    {
      path: "/brokers/:brokerId/sales",
      element: (
        <ProtectedRoute accessKey="BROKER_VIEW">
          <BrokerDashboardSales />
        </ProtectedRoute>
      ),
      exact: true,
    },
    {
      path: "/brokers/:brokerId/visit",
      element: (
        <ProtectedRoute accessKey="BROKER_VIEW">
          <BrokerDashboardVisit />
        </ProtectedRoute>
      ),
      exact: true,
    },
    {
      path: "/brokers/comissions/pending",
      element: (
        <ProtectedRoute accessKey="BROKER_VIEW">
          <PendingComissionList />
        </ProtectedRoute>
      ),
      exact: true,
    },
  ],
};
