//eslint-disable-next-line
export default {
  red: "#ff0000",
  black: "#212427",
  gray_light: "#f4f6f9",
  gray_dark: "#7c8091",
  green: "#73af00",
  gray_text: "#8792A4",
  white: "#fff",
  yellow: "#f39c12",
  gray: "#7c8091",
  blue: "rgb(16, 142, 233)",
  black_light:'#303131'
};
