import { Form, Select } from "antd";
import Button from "app/shared/Button";
import Input from "app/shared/InputBox";
import Typography from "app/shared/Typography";
import { useState } from "react";
import "../../styles/onboarding.scss";

import { connect, useDispatch } from "react-redux";
import OTPBox from "app/shared/OTPBox";
import { DOCUMENT_TYPE } from "app/constants/global.constants";
import Uploader from "app/shared/Uploader";
import { ConsoleSqlOutlined } from "@ant-design/icons";
import { apiPostForm } from "app/services/apiService";
import { API_ENDPOINT_PROFILE_UPDATE_DOCUMENT } from "../../onboard.constants";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import { useNavigate } from "react-router";

function OnbaordingDocuments(props) {
  const [documentType, setDocumentType] = useState("ADHAAR");
  const [documentNumber, setDocumentNumber] = useState("");
  const [imageFront, setImageFront] = useState(null);
  const [imageBack, setImageBack] = useState(null);
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const uploadDocument = async () => {
    const payload = {
      document_no: documentNumber,
      type: documentType,
      file: imageFront,
      // file: imageBack,
    };
    let formData = new FormData();
    formData.append("file", imageFront);
    formData.append("file", imageBack);
    formData.append("document_no", documentNumber);
    formData.append("doc_type", documentType);

    console.log("payload:", payload);
    const response = await apiPostForm(
      API_ENDPOINT_PROFILE_UPDATE_DOCUMENT,
      formData
    );
    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
      navigate("/onboard/profile_bank");
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
  };
  return (
    <Form
      name="basic"
      initialValues={{ remember: true }}
      onFinish={uploadDocument}
      // onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <Typography
        style={{ textAlign: "center", marginBottom: 64 }}
        variant="h5"
      >
        Update Documents
      </Typography>

      <div className="input-element" style={{ marginBottom: 24 }}>
        <Typography variant="body" weight={400} style={{ marginLeft: 4 }}>
          Document Tye
        </Typography>
        <Select
          defaultValue="ADHAAR"
          style={{ width: "100%", height: "2.8rem" }}
          onChange={(value) => setDocumentType(value)}
          options={DOCUMENT_TYPE}
        />
      </div>

      <div className="input-element" style={{ marginBottom: 24 }}>
        <Form.Item
          name="document"
          rules={[
            {
              required: true,
              message: "Please enter document number!",
              fontSize: 12,
            },
          ]}
          style={{ fontSize: 12 }}
        >
          <Input
            placeholder="Document No"
            label="Document No"
            onChange={(e) => setDocumentNumber(e.target.value)}
          />
        </Form.Item>
      </div>

      <div
        className="input-element"
        style={{ marginBottom: 24, display: "flex" }}
      >
        <Uploader
          label="Front Image"
          onUpload={(file) => setImageFront(file)}
        />
        <Uploader label="Back Image" onUpload={(file) => setImageBack(file)} />
      </div>

      <div className="action-buttons">
        <Form.Item style={{ width: "100%" }}>
          <Button
            type="primary"
            size="large"
            style={{ width: "100%" }}
            htmlType="submit"
          >
            Update
          </Button>
        </Form.Item>
      </div>
    </Form>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
});

export default connect(mapStateToProps, {})(OnbaordingDocuments);
