// import { PageNotFound } from "app/scene/Common";

import authRoute from "app/scenes/auth/auth.routes";
import onboardingRoutes from "app/scenes/onboarding/onboarding.routes";
import QuoatationView from "app/scenes/openRoutes/QuoatationView";
import masterLayoutRoute from "app/shared/MasterLayout/master.layout.route";

const childRoutes = [authRoute, onboardingRoutes, masterLayoutRoute];

const routes = [
  ...childRoutes,
  {
    path: "/open_view/quoatation/:propertyId",
    element: <QuoatationView />,
  },
  //   {
  //     path: "*",
  //     exact: true,
  //     component: PageNotFound,
  //   },
];

export default routes;
