// import ProtectedRoute from "app/utils/ProtectedRoute";
import ProtectedRoute from "app/utils/ProtectedRoute";
import Employee from ".";
import EmployeeList from "./components/EmployeeList";
//eslint-disable-next-line
export default {
  path: "/employee",
  element: <Employee />,
  children: [
    {
      path: "/employee/list",
      element: (
        <ProtectedRoute accessKey="EMPLOYEE_VIEW">
          <EmployeeList />
        </ProtectedRoute>
      ),
      exact: true,
    },
  ],
};
