import {
  CheckOutlined,
  CloseCircleOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { ConfigProvider, DatePicker, Form, Modal } from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  EMPLOYEE_LEAVE_TYPES,
  GLOBAL_TIME_FORMAT,
  LEAD_SOURCE_MAP,
  USER_GENDER_MAP,
} from "app/constants/global.constants";
import { API_ENDPOINT_LEAD_CREAETE } from "app/scenes/leads/leads.constants";
import {
  API_ENDPOINT_LEAVE_APPROVE_REJECT_LEAVE,
  API_ENDPOINT_LEAVE_CREATE_REQUEST,
} from "app/scenes/leaves/leaves.constants";
import { apiPost } from "app/services/apiService";
import Button from "app/shared/Button";
import Input from "app/shared/InputBox";
import SearchSelector from "app/shared/SearchSelector";
import Selector from "app/shared/Selector";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import Typography from "app/shared/Typography";
import { parseURL } from "app/utils/helpers/common.helper";
import moment from "moment";
import React, { useState } from "react";
import { useDispatch } from "react-redux";

export default function CreateLead(props) {
  const [isLoading, setLoading] = useState(false);
  const [formData, setFormData] = useState({});

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  const dispatch = useDispatch();

  const createLeadInfo = async () => {
    setLoading(true);
    const payload = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      mobile: formData.mobile,
      email: formData.email,
      remarks: formData.remarks,
      source: formData.source,
      gender: formData.gender,
      projectId: formData.project,
    };

    const response = await apiPost(API_ENDPOINT_LEAD_CREAETE, payload);

    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
      props.onSuccess && props.onSuccess();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };

  return (
    <Form
      name="basic"
      initialValues={{
        remember: true,
        ...formData,
      }}
      onFinish={createLeadInfo}
      // onFinishFailed={onFinishFailed}
      autoComplete="off"
    >
      <div className="filterWidget" style={{ marginBottom: 12 }}>
        <Form.Item
          name="first_name"
          rules={[
            {
              required: true,
              message: "Please check your input",
            },
          ]}
        >
          <Input
            placeholder="First Name"
            label="First Name"
            onChange={handleInputChange}
            name="first_name"
          />
        </Form.Item>
      </div>
      <div className="filterWidget" style={{ marginBottom: 12 }}>
        <Form.Item
          name="last_name"
          rules={[
            {
              required: true,
              message: "Please check your input",
            },
          ]}
        >
          <Input
            placeholder="Last Name"
            label="Last Name"
            onChange={handleInputChange}
            name="last_name"
          />
        </Form.Item>
      </div>

      <div className="filterWidget" style={{ marginBottom: 12 }}>
        <Form.Item
          name="email"
          rules={[
            {
              required: true,
              type: "email",
              message: "Please check your email",
            },
          ]}
        >
          <Input
            placeholder="Email"
            label="Email"
            onChange={handleInputChange}
            name="email"
          />
        </Form.Item>
      </div>

      <div className="filterWidget" style={{ marginBottom: 12 }}>
        <Form.Item
          name="mobile"
          rules={[
            {
              required: true,
              min: 10,
              message: "Please check your mobile",
            },
          ]}
        >
          <Input
            placeholder="Mobile"
            label="Mobile"
            onChange={handleInputChange}
            name="mobile"
            maxLength={10}
          />
        </Form.Item>
      </div>
      <div className="filterWidget" style={{ marginBottom: 12 }}>
        <Typography variant="caption" style={{ marginBottom: 4 }}>
          Gender
        </Typography>
        <Selector
          placeholder="Leave Type"
          labelKey="name"
          valueKey="id"
          serviceType="STATIC"
          onChange={handleInputChange}
          staticData={USER_GENDER_MAP}
          autoSelect="MALE"
          name="gender"
        />
      </div>

      <div className="filterWidget" style={{ marginBottom: 12 }}>
        <Typography variant="caption" style={{ marginBottom: 4 }}>
          Source
        </Typography>
        <Selector
          placeholder="Leave Type"
          labelKey="name"
          valueKey="id"
          serviceType="STATIC"
          onChange={handleInputChange}
          staticData={LEAD_SOURCE_MAP}
          autoSelect="WEBSITE"
          name="source"
        />
      </div>

      <div className="filterWidget" style={{ marginBottom: 12 }}>
        <Typography variant="caption" style={{ marginBottom: 4 }}>
          Project
        </Typography>
        <SearchSelector
          placeholder="Project Id (#PROJ-12)"
          serviceType="PROJECT"
          valueKey="id"
          labelKey="projectUniqueCode"
          onChange={(value) =>
            handleInputChange({ target: { name: "project", value: value } })
          }
        />
      </div>

      <div className="filterWidget" style={{ marginBottom: 12, marginTop: 12 }}>
        <Form.Item name="remarks" style={{ fontSize: 12 }}>
          <TextArea
            //   value={value}
            //   onChange={(e) => setValue(e.target.value)}
            placeholder="Please add remarks here...."
            style={{
              backgroundColor: "#F4F6F9",
              width: "100%",
              border: "none",
              borderRadius: 12,
            }}
            name="remarks"
            onChange={(e) =>
              handleInputChange({
                target: { name: "remarks", value: e.target.value },
              })
            }
            autoSize={{ minRows: 3, maxRows: 5 }}
          />
        </Form.Item>
      </div>
      <div className="action-buttons">
        <Form.Item style={{ width: "100%" }}>
          <div className="filterWidget" style={{ marginTop: 44 }}>
            <Button
              type="primary"
              size="large"
              style={{ width: "100%" }}
              htmlType="submit"
              loading={isLoading}
            >
              Create
            </Button>
          </div>
        </Form.Item>
      </div>
    </Form>
  );
}
