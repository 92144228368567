import { ADD_TOAST_MESSAGE } from "../toast.constants";

const addToast = (options) => {
  return {
    payload: createToast(options),
    type: ADD_TOAST_MESSAGE,
  };
};

let id = 0;

const defaultOptions = {};

const createToast = (options) => {
  return {
    ...defaultOptions,
    ...options,
    id: id++,
  };
};

export default addToast;
