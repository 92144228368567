import { Button as AntButton } from "antd";
import { colorPicker } from "app/utils/helpers/color.helper";

export default function Button(props) {
  const buttonHeight = {
    small: 32,
    medium: 40,
    large: 50,
  };
  return (
    <AntButton
      type={props.type || "primary"}
      size={props.size || "large"}
      style={{
        borderRadius: 10,
        width: props.style?.width || "100%",
        height: buttonHeight[props.size] || 50,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        backgroundColor:
          props.type === "link"
            ? "transparent"
            : colorPicker(props.background || "red"),
        boxShadow: "none",
      }}
      htmlType={props.htmlType}
      // {...props}
      onClick={props.onClick && props.onClick}
      danger={props.danger}
      disabled={props.disabled}
      loading={props.loading}
      icon={props.icon && props.icon}
    >
      {props.children || "Button"}
    </AntButton>
  );
}
