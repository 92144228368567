import { MinusOutlined, PlusOutlined } from "@ant-design/icons";
import { Drawer, Tag } from "antd";
import {
  API_ENDPOINT_PROJECT_INVESTOR_ADD_INVESTMENT,
  API_ENDPOINT_PROJECT_INVESTOR_INFO,
} from "app/scenes/property/property.constants";
import { apiGet, apiPost } from "app/services/apiService";
import Button from "app/shared/Button";
import Input from "app/shared/InputBox";
import CreateInternalUser from "app/shared/InternalUserCreate";
import Loader from "app/shared/Loader";
import SearchSelector from "app/shared/SearchSelector";
import TableView from "app/shared/TableView";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/helpers/color.helper";
import { parseURL } from "app/utils/helpers/common.helper";
import React, { useState } from "react";
import { useEffect } from "react";
import { FormattedNumber } from "react-intl";
import { useDispatch } from "react-redux";
import "../../InvestorList.scss";

const tableColumn = [
  {
    title: "Investment Amount",
    index: "amount",
    amount: true,
  },

  {
    title: "Project",
    index: "property_project.name",
  },

  {
    title: "Invested On",
    index: "createdAt",
    date: true,
  },
];
export default function InvestorDetail(props) {
  const [isLoading, setLoading] = useState(true);
  const [investorInfo, setInvestorInfo] = useState(null);
  const [showAddForm, setShowAddForm] = useState(false);
  const [amount, setAmount] = useState("");
  const [projectId, setProjectId] = useState("");
  const [totalInvestment, setTotalInvestment] = useState(0);
  const dispatch = useDispatch();
  useEffect(() => {
    fetchInvestorInfo(true);
  }, [props.investorId]);
  const fetchInvestorInfo = async (loading = true) => {
    setLoading(loading);
    const response = await apiGet(
      API_ENDPOINT_PROJECT_INVESTOR_INFO + props.investorId
    );
    if (response.status) {
      setInvestorInfo(response.data);
      let total_investment = 0;
      (response.data?.investments || []).forEach((element) => {
        total_investment += parseInt(element.amount);
      });
      setTotalInvestment(total_investment);
    }
    setLoading(false);
  };

  const addInvestment = async () => {
    const paylaod = {
      amount: amount,
      project_id: projectId,
    };
    const response = await apiPost(
      parseURL(API_ENDPOINT_PROJECT_INVESTOR_ADD_INVESTMENT, {
        investorId: props.investorId,
      }),
      paylaod
    );
    if (response.status) {
      fetchInvestorInfo(false);
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
      setShowAddForm(false);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
  };
  return isLoading ? (
    <Loader />
  ) : (
    <div className="InvestorDetail">
      <div
        className="InvestorBasicInfo"
        style={{ display: "flex", flexWrap: "wrap", marginTop: 28 }}
      >
        <div className="InfoGroup" style={{ width: "30%", marginBottom: 24 }}>
          <Typography variant="xs">Name</Typography>
          <Typography>
            {investorInfo && investorInfo?.user_profile?.fullname}
          </Typography>
        </div>

        <div className="InfoGroup" style={{ width: "30%", marginBottom: 24 }}>
          <Typography variant="xs">Mobile</Typography>
          <Typography>
            {investorInfo && investorInfo?.user_profile?.mobile}
          </Typography>
        </div>

        <div className="InfoGroup" style={{ width: "30%", marginBottom: 24 }}>
          <Typography variant="xs">Email</Typography>
          <Typography> {investorInfo && investorInfo?.email}</Typography>
        </div>
        <div className="InfoGroup" style={{ width: "30%", marginBottom: 24 }}>
          <Typography variant="xs">Individual/Insitution</Typography>
          <Tag color="blue">
            {" "}
            {(investorInfo && investorInfo?.user_profile?.userType) ||
              "INDIVIDUAL"}
          </Tag>
        </div>

        <div className="InfoGroup" style={{ width: "30%", marginBottom: 24 }}>
          <Typography variant="xs">Total Investment</Typography>
          <Typography variant="caption">
            <b style={{ fontWeight: 700, color: colorPicker("green") }}>
              <FormattedNumber
                value={totalInvestment}
                style="currency"
                currency={"INR"}
                minimumFractionDigits={0}
                maximumFractionDigits={0}
              />
            </b>
          </Typography>
        </div>
      </div>
      <div className="TableContainer">
        <div className="TableTopAction" style={{ marginBottom: 12 }}>
          <Tag
            style={{ cursor: "pointer" }}
            onClick={() => setShowAddForm(!showAddForm)}
          >
            {!showAddForm ? (
              <>
                <PlusOutlined /> Add Investment
              </>
            ) : (
              <>
                {" "}
                <MinusOutlined /> Hide Add Investment
              </>
            )}
          </Tag>
          {showAddForm ? (
            <div
              style={{
                display: "flex",
                marginTop: 12,
                marginBottom: 12,
                width: "100%",
              }}
            >
              <div style={{ marginRight: 24, width: "30%" }}>
                <Input
                  placeholder="Amount"
                  onChange={(e) => setAmount(e.target.value)}
                  height={30}
                />
              </div>
              <div style={{ marginRight: 24, width: "40%" }}>
                <SearchSelector
                  placeholder="Project Id (#PROJ-12)"
                  serviceType="PROJECT"
                  valueKey="id"
                  labelKey="projectUniqueCode"
                  onChange={(value) => setProjectId(value)}
                />
              </div>
              <div style={{ marginRight: 24, width: "40%" }}>
                <Button
                  size="small"
                  height={30}
                  disabled={!projectId || !amount}
                  onClick={addInvestment}
                >
                  Add
                </Button>
              </div>
            </div>
          ) : null}
        </div>

        <TableView
          data={investorInfo?.investments}
          isLoading={isLoading}
          column={[...tableColumn]}
        />
      </div>
    </div>
  );
}
