import Typography from "app/shared/Typography";
import paymentIcon from "assets/images/payments.svg";
import ArrowRightAltIcon from "@mui/icons-material/ArrowRightAlt";
import "./LastPayment.scss";
import { colorPicker } from "app/utils/helpers/color.helper";
import CallMadeIcon from "@mui/icons-material/CallMade";
import CurrencyRupeeIcon from "@mui/icons-material/CurrencyRupee";
import CallReceivedIcon from "@mui/icons-material/CallReceived";
import { useEffect, useState } from "react";
import { apiGet } from "app/services/apiService";
import { API_ENDPOINT_DASHBOARD_FETCH_LAST_PAYMENT_INFO } from "../../dashboard.constants";
import { useDispatch } from "react-redux";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import { FormattedNumber } from "react-intl";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Popover } from "antd";
export default function LastPayment(props) {
  const [isLoading, setLoading] = useState(true);
  const [paymentInfo, setPaymentInfo] = useState(null);

  const dispatch = useDispatch();

  useEffect(() => {
    fetchPaymentInfo();
  }, []);
  const fetchPaymentInfo = async () => {
    setLoading(true);
    const response = await apiGet(
      API_ENDPOINT_DASHBOARD_FETCH_LAST_PAYMENT_INFO +
        "?start=2024-01-14T16:04:35.000Z&end=2024-01-14T16:04:35.000Z"
    );
    if (response.status) {
      setPaymentInfo(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };

  return (
    <div
      className="LastPayment"
      style={{
        width: "100%",
        height: "100%",
        display: "flex",
        flexDirection: "column",
      }}
    >
      <div className="TopHeader">
        <div className="titleContainer">
          <img src={paymentIcon} alt="Icon" style={{ width: 62 }} />
          <div className="Title">
            <Typography variant="h6">Last Payments</Typography>
            <Typography variant="caption" color="gray-dark">
              Updated on 23rd Jan 2023
            </Typography>
          </div>
        </div>

        <Popover
          placement="bottom"
          title={"Last Payments"}
          content={
            "Understand how you as a broker making money from belara platform"
          }
        >
          <InfoCircleOutlined />
        </Popover>
      </div>
      <div className="Stats">
        <div className="TranscationCard">
          <div
            className="TopContent"
            style={{ backgroundColor: `${colorPicker("green")}1A` }}
          >
            <div className="IconCircle">
              <CallReceivedIcon
                style={{ fontSize: 16, color: colorPicker("green") }}
              />
            </div>
            <Typography variant="caption">Received</Typography>
          </div>
          <div
            className="BottomContent"
            style={{ backgroundColor: colorPicker("green") }}
          >
            <Typography
              variant="h6"
              style={{ color: "white", fontWeight: 600 }}
            >
              <FormattedNumber
                value={(paymentInfo && paymentInfo?.paid_earning) || 0}
                style="currency"
                currency={"INR"}
                minimumFractionDigits={0}
                maximumFractionDigits={0}
              />
            </Typography>
          </div>
        </div>
        <div className="TranscationCard">
          <div
            className="TopContent"
            style={{ backgroundColor: `${colorPicker("red")}1A` }}
          >
            <div className="IconCircle">
              <CallMadeIcon
                style={{ fontSize: 16, color: colorPicker("red") }}
              />
            </div>
            <Typography variant="caption">Pending</Typography>
          </div>
          <div
            className="BottomContent"
            style={{ backgroundColor: colorPicker("red") }}
          >
            <Typography
              variant="h6"
              style={{ color: "white", fontWeight: 600 }}
            >
              <FormattedNumber
                value={(paymentInfo && paymentInfo?.unpaid_earning) || 0}
                style="currency"
                currency={"INR"}
                minimumFractionDigits={0}
                maximumFractionDigits={0}
              />
            </Typography>
          </div>
        </div>
      </div>
    </div>
  );
}
