export const DOCUMENT_TYPE = [
  {
    label: "Adhaar",
    value: "ADHAAR",
  },
  {
    label: "Pan",
    value: "PAN",
  },
  {
    label: "Voter ID",
    value: "VOTER_ID",
  },
  {
    label: "Passport",
    value: "PASSPORT",
  },
  {
    label: "Other",
    value: "OTHER",
  },
];

export const GLOBAL_TIME_FORMAT = "YYYY-MM-DDTHH:mm:ss.SSS[Z]";

export const COMISSION_TYPES = [
  {
    label: "Progressive Percentage",
    value: "PROGRESSIVE",
  },
  {
    label: "Fixed",
    value: "FIXED",
  },
];

export const PROPERTY_DOCUMENT_TYPE = [
  "FLOOR_PLAN",
  "UNIT_PLAN",
  "PLOT_PLAN",
  "QUOTATION",
  "BROCHURE",
  "PAYMENT_PLAN",
];

export const PROPERTY_DOCUMENT_TYPE_FOR_CREATE = [
  {
    type: "FLOOR_PLAN",
    key: "floor_plan",
  },
  {
    type: "UNIT_PLAN",
    key: "unit_plan",
  },
  {
    type: "PLOT_PLAN",
    key: "plot_plan",
  },
  {
    type: "BROCHURE",
    key: "brochure",
  },
];

export const ACCOUNT_STATUS_MAP = {
  ACTIVE: "Approved",
  PENDING: "Pending",
  DELETED: "Deleted",
  REJECTED: "Rejected",
  DISABLED: "Disabled",
};

export const ACCOUNT_STATUS = {
  ACTIVE: "ACTIVE",
  PENDING: "PENDING",
  DELETED: "DELETED",
  REJECTED: "REJECTED",
  DISABLED: "DISABLED",
};

export const ACCOUNT_STATUS_MAP_COLOR = {
  ACTIVE: "green",
  PENDING: "yellow",
  DELETED: "red",
  REJECTED: "red",
  DISABLED: "gray",
  INITIATED: "yellow",
  TRANSFERRED: "green",
};
export const ENUM_KEY_TITLES = {
  READY_TO_MOVE: "Ready to move",
  NEW_PROPERTY: "New Property",
  RESALE: "Resale",
  UNFURNISHED: "Unfurnished",
  FULLY_FURNISHED: "Full Furnished",
  SEMI_FURNISHED: "Semi Furnished",
  UNDER_CONSTRUCTION: "Under Construction",
};
export const LAND_AREA_SIZE_UNITS = [
  {
    label: "sqft",
    value: "sqft",
  },
  {
    label: "sqyrd",
    value: "sqyrd",
  },
  {
    label: "acre",
    value: "acre",
  },
  {
    label: "bigha",
    value: "bigha",
  },
  {
    label: "katha",
    value: "katha",
  },
  {
    label: "gaj",
    value: "gaj",
  },
];

export const DEPARTMENT_TYPES = [
  {
    text: "Managment",
    value: "Managment",
  },
  {
    text: "Account/Finance",
    value: "Account/Finance",
  },
  {
    text: "Engineering",
    value: "Engineering",
  },
];

export const DESIGNATION_TYPES = [
  {
    text: "DevOps",
    value: "DevOps",
  },
  {
    text: "Lead Accountant",
    value: "Lead Accountant",
  },
  {
    text: "Application Engineer",
    value: "Application Engineer",
  },
  {
    text: "Cheif People Person",
    value: "Cheif People Person",
  },
];
export const PROPERTY_TYPES = [
  {
    text: "Flat",
    value: "Flat",
  },
  {
    text: "House/Villa",
    value: "House/Villa",
  },
  {
    text: "Plot",
    value: "Plot",
  },
  {
    text: "Office Space",
    value: "Office Space",
  },
  {
    text: "Shop/Showroom",
    value: "Shop/Showroom",
  },
  {
    text: "Industrial Building",
    value: "Industrial Building",
  },
];

export const PROPERTY_TYPES_BHK_FILTERS = [
  {
    text: "Studio",
    value: "STUDIO",
  },
  {
    text: "1BHK",
    value: 1,
  },
  {
    text: "2BHK",
    value: 2,
  },
  {
    text: "3BHK",
    value: 3,
  },
  {
    text: "4BHK",
    value: 4,
  },
  {
    text: "5BHK",
    value: 5,
  },
  {
    text: "5+ BHK",
    value: 6,
  },
];
export const FLOOR_MAP = [
  {
    name: "BASEMENT",
    id: -1,
  },
  {
    name: "GROUND",
    id: "0",
  },
  {
    name: 1,
    id: 1,
  },
  {
    name: 2,
    id: 2,
  },
  {
    name: 4,
    id: 4,
  },
  {
    name: 5,
    id: 5,
  },
  {
    name: 3,
    id: 3,
  },

  {
    name: 6,
    id: 6,
  },
  {
    name: 7,
    id: 7,
  },
  {
    name: 8,
    id: 8,
  },
  {
    name: 9,
    id: 9,
  },
  {
    name: 10,
    id: 10,
  },
  {
    name: "10+",
    id: "10+",
  },
];

export const GLOBAL_VIDEO_FORMATS = ["mp4", "mov", "wmv", "avi", "mkv"];
export const FURNISHING_TYPES = [
  {
    name: "Fully Furnished",
    id: "FULLY_FURNISHED",
  },
  {
    name: "Semi Furnished",
    id: "SEMI_FURNISHED",
  },
  {
    name: "Unfurnished",
    id: "UNFURNISHED",
  },
];

export const PROPERTY_FACING_TYPE = [
  {
    name: "East",
    id: "EAST",
  },
  {
    name: "West",
    id: "WEST",
  },
  {
    name: "North",
    id: "NORTH",
  },
  {
    name: "South",
    id: "SOUTH",
  },
];

export const SEAT_CAPACITY_MAPS = [
  {
    name: "0-10",
    id: [0, 10],
  },
  {
    name: "10-25",
    id: [10, 25],
  },
  {
    name: "25-50",
    id: [25, 50],
  },
  {
    name: "50-100",
    id: [50, 100],
  },
  {
    name: "100-200",
    id: [100, 200],
  },
  {
    name: "200-500",
    id: [200, 500],
  },
  {
    name: "500-1000",
    id: [500, 1000],
  },
];

export const CABIN_MAPS = [
  {
    name: 1,
    id: 1,
  },
  {
    name: 2,
    id: 2,
  },
  {
    name: 3,
    id: 3,
  },
  {
    name: 4,
    id: 4,
  },
  {
    name: 5,
    id: 5,
  },
];

export const LEAD_STATUS_COLOR_MAP = {
  NEW: "yellow",
  LEAD_CONTACT_MADE: "yellow",
  SUBMIT_FOR_APPROVAL: "yellow",
  ASSIGN_TO_SALES: "yellow",
  SALES_CONTACT_MADE: "yellow",
  PROPERTY_VISIT: "green",
  QUOATATION_GENERATED: "green",
  FOLLOW_UP: "green",
  PRE_CLOSE: "green",
  CONVERTED: "green",
  DISQUALIFIED: "red",
  QUALIFIED: "green",
  CLOSED: "green",
};

export const LEAD_ACTIVITY_TYPES = [
  {
    id: "CALL",
    name: "Call",
  },
  {
    id: "EMAIL",
    name: "Email",
  },
  {
    id: "EVENT",
    name: "Visit",
  },
  {
    id: "TASK",
    name: "Task",
  },
  {
    id: "LOG",
    name: "Activity Log",
  },
];

export const USER_GENDER_MAP = [
  {
    id: "MALE",
    name: "Male",
  },
  {
    id: "FEMALE",
    name: "Female",
  },
];

export const LEAD_SOURCE_MAP = [
  {
    id: "WEBSITE",
    name: "Website",
  },
  {
    id: "INSTAGRAM",
    name: "Instrgram",
  },
  {
    id: "FACEBOOK",
    name: "Facebook",
  },
  {
    id: "GOOGLE",
    name: "Google",
  },
  {
    id: "CAMPAIGN",
    name: "Campaign",
  },
];

export const EMPLOYEE_LEAVE_TYPES = [
  {
    id: "PAID",
    name: "Paid Leave",
  },
  {
    id: "UNPAID",
    name: "Un Paid Leave",
  },
  {
    id: "MATERNITY",
    name: "Maternity Leave",
  },
  {
    id: "PATERNITY",
    name: "Paternitiy Leave",
  },
];

export const LEAD_JOURNEY_ID_MAP = {
  1: 0,
  2: 0,
  3: 0,
  4: 0,
  5: 1,
  6: 2,
  7: 3,
  8: 3,
  9: 3,
  10: 3,
  11: 3,
  12: 3,
  13: 5,
};

export const PROJECT_STATUS_MAP = [
  {
    id: "NOT_AVAILABLE",
    name: "Not Available",
  },
  {
    id: "BUILDING",
    name: "Building",
  },
  {
    id: "PREPARING_SELLING",
    name: "Preparing Selling",
  },
  {
    id: "SELLING",
    name: "Selling",
  },
  {
    id: "SOLD",
    name: "Sold",
  },
];

export const FLAT_PROPERTY_TYPE = [
  {
    id: "STUDIO",
    name: "Studio",
  },
  {
    id: "1BHK",
    name: "1BHK",
  },
  {
    id: "2BHK",
    name: "2BHK",
  },
  {
    id: "3BHK",
    name: "3BHK",
  },
];

export const ANNOUNCEMENT_SEND_TO_MAP = [
  // {
  //   value: "Everyone",
  //   label: "Everyone",
  // },
  {
    value: "BROKER",
    label: "Brokers",
  },
  {
    value: "PROMOTER",
    label: "Promoters",
  },
  {
    value: "MANAGER",
    label: "Managers",
  },
  {
    value: "INVESTOR",
    label: "Investors",
  },
];

export const REPORT_TYPE_MAP = [
  {
    value: "BROKER_EARNINGS",
    label: "Broker Earnings",
  },
  // {
  //   value: "LEAD_CONVERSION",
  //   label: "Lead Conversion",
  // },
  // {
  //   value: "SALES_GROWHT",
  //   label: "Sale Growth",
  // },
  // {
  //   value: "TOP_PERFORMERS",
  //   label: "Top Performers",
  // },
];

export const PROPRERTY_STATUS_FILTER_MAP = [
  {
    value: "APPROVED",
    text: "Approved",
  },
  {
    value: "AVAILABLE",
    text: "Available",
  },
  {
    value: "DISABLED",
    text: "Disabeld",
  },
  {
    value: "HOLD",
    text: "Hold",
  },
  {
    value: "UNDER_REVIEW",
    text: "Under Review",
  },
  {
    value: "BOOKED",
    text: "Booked",
  },
  {
    value: "SOLD",
    text: "Sold",
  },
];

export const BEDROOM_TYPE_COUNT_MAP = [
  {
    label: 1,
    value: 1,
  },
  {
    label: 2,
    value: 2,
  },
  {
    label: 3,
    value: 3,
  },
  {
    label: 4,
    value: 4,
  },
  {
    label: 5,
    value: 5,
  },

  {
    label: 6,
    value: 6,
  },
  {
    label: 7,
    value: 7,
  },
  {
    label: 8,
    value: 8,
  },
  {
    label: 9,
    value: 9,
  },
  {
    label: 10,
    value: 10,
  },
];
