export default {
  "/property/list": "My Units",
  "/property/create": "Add Unit",
  "/brokers/list": "Brokers",
  "/promoters/list": "Promoter Management",
  "/employee/list": "Employee Management",
  "/leads/list": "Leads Management",
  "/settings": "Settings",
  "/leaves/dashboard": "Leaves",
  "/property/investors": "Investors",
  "/property/manage": "Manage Unit",
  "/property/amentities": "Amentity",
  "/property/facilities": "Facilities",
  "/property/types": "Unit Category",
  "/property/banks": "Banks",
  "/property/payment_plan": "Payment Plans",
  "/property/projects": "Projects",
  "/brokers/comissions/pending": "Pending Comissions",
  "customer/list": "Customers",
  "/brokers/": "Broker Dashboard",
  "/property/project/unit/edit/": "Edit Unit",
  "/property/project/unit/create/": "Add Unit",
  "property/project/": "Project Dashboard",
  "property/project/": "Project Info",
  "/property/inventory": "Unit Inventory",
  "/offers/list": "Offers",
  "/announcement/list": "Announcements",
  "/reports/list": "Reports",
  "/dashboard": "Dashboard",
  "/property/developers": "Project Developers",
  "/property/community": "Project Community",
  "/property/sales": "Property Sales",
  "/property/": "Unit Details",
};
