import Button from "app/shared/Button";
import Input from "app/shared/InputBox";
import { useState } from "react";

export default function PriceCalculator(props) {
  const [cost, setCost] = useState("");

  const handleCalculate = () => {
    props.onCalculate &&
      props.onCalculate(parseFloat(props.size) * parseFloat(cost));
  };
  return (
    <div className="ItemRow" style={{ width: "100%" }}>
      <div style={{ display: "flex", alignItems: "center", marginBottom: 24 }}>
        <div style={{ width: "48%" }}>
          <Input
            label={`Size (${props.unitSizeType || "ft"})`}
            placeholder="Size"
            value={props.size}
          />
        </div>

        <div style={{ width: "48%" }}>
          <Input
            placeholder="Cost "
            label="Cost"
            onChange={(e) => setCost(e.target.value)}
          />
        </div>
      </div>

      <div>
        <Button onClick={handleCalculate}>Calculate</Button>
      </div>
    </div>
  );
}
