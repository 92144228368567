import React from "react";
import "./HouseInfoCard.scss";
import BusinessRoundedIcon from "@mui/icons-material/BusinessRounded";
import MapsHomeWorkOutlinedIcon from "@mui/icons-material/MapsHomeWorkOutlined";
import Typography from "app/shared/Typography";
import { colorPicker } from "app/utils/helpers/color.helper";
export default function HouseInfoCard(props) {
  return (
    <div className="HouseInfoCard">
      <div className="CardTitle">
        {props.type === "Commercial" ? (
          <BusinessRoundedIcon
            style={{ color: colorPicker("gray_dark"), marginRight: 6 }}
          />
        ) : (
          <MapsHomeWorkOutlinedIcon
            style={{ color: colorPicker("gray_dark"), marginRight: 6 }}
          />
        )}
        <Typography color="gray-dark">
          Total {props.type} {props.titleType === "SALES" ? " Sale" : " Visit"}
        </Typography>
      </div>
      <div className="InfoContainer">
        <Typography variant="h4" color="green">
          {props.data && props.data.length}
        </Typography>
      </div>
    </div>
  );
}
