import axios from "axios";
import React, { useState } from "react";
import RenderRazorpay from "./Razorpay";
export default function TestPayment(props) {
  const [displayRazorpay, setDisplayRazorpay] = useState(false);
  const [orderDetails, setOrderDetails] = useState({
    orderId: null,
    currency: null,
    amount: null,
  });

  const handleCreateOrder = async (amount, currency) => {
    const response = await axios.post(
      "http://localhost:4000/api/payment/initiate",
      {
        amount: amount * 100, //convert amount into lowest unit. here, Dollar->Cents
        currency,
        keyId: "rzp_test_1MOo71qMF8lPlC",
        KeySecret: "pj9ExTqmtFZgYKmedeLUWT2F",
        transaction_type: "BOOKING",
      },
      {
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + localStorage.getItem("token"),
        },
      }
    );
    console.log(response);
    if (response && response.data.data.order_id) {
      setOrderDetails({
        orderId: response.data.data.order_id,
        currency: response.data.data.currency,
        amount: response.data.data.amount,
      });
      setDisplayRazorpay(true);
    }
  };

  return (
    <div>
      <button onClick={() => handleCreateOrder(100, "INR")}>Place Order</button>

      {displayRazorpay && (
        <RenderRazorpay
          amount={orderDetails.amount}
          currency={orderDetails.currency}
          orderId={orderDetails.orderId}
          keyId={"rzp_test_1MOo71qMF8lPlC"}
          keySecret={"pj9ExTqmtFZgYKmedeLUWT2F"}
        />
      )}
    </div>
  );
}
