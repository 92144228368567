import React from "react";

export const FeatureFlags = React.createContext({});

export const FeatureFlagsProvider = (
  { children, feature_flag_values },
  props
) => {
  const hasAccess = (roleKey) => {
    return feature_flag_values.includes(roleKey);
  };
  return (
    <FeatureFlags.Provider value={{ hasAccess }}>
      {children}
    </FeatureFlags.Provider>
  );
};
