import { Form, Slider } from "antd";
import { API_ENDPOINT_BROKERS_INVITE } from "app/scenes/brokers/brokers.constants";
import { API_ENDPOINT_USER_INTERNAL_CREATE } from "app/scenes/promoters/promoters.constants";
import { apiPost } from "app/services/apiService";
import Button from "app/shared/Button";
import Input from "app/shared/InputBox";
import LocationFinder from "app/shared/LocationFinder";
import Selector from "app/shared/Selector";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Typography from "../Typography";
import LockResetIcon from "@mui/icons-material/LockReset";
import { colorPicker } from "app/utils/helpers/color.helper";
const userTypeMap = {
  PROMOTER: "Promoter",
};
export default function CreateInternalUser(props) {
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [first_name, setFirstName] = useState("");
  const [last_name, setLastName] = useState("");
  const [department, setDepartment] = useState("");
  const [desgination, setDesignation] = useState("");
  const [mobile, setMobile] = useState("");

  const [isLoading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const handleRegister = async () => {
    setLoading(true);
    const payload = {
      email: email,
      first_name: first_name,
      last_name: last_name,
      department: department,
      designation_id: desgination,
      role: role,
    };
    if (
      props.userType === "Employee" &&
      (!payload.department || !payload.designation_id)
    ) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: {
          message: "Department or Designation is not selected",
          type: 0,
        },
      });
      setLoading(false);
      return;
    }
    if (props.userType === "Investor") payload.mobile = mobile;

    const response = await apiPost(API_ENDPOINT_USER_INTERNAL_CREATE, payload);
    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
      props.onCreate && props.onCreate();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };
  return (
    <div>
      <div className="filterWidget" style={{ marginBottom: 12 }}>
        <Typography variant="body" weight={400} style={{ marginBottom: 4 }}>
          Role
        </Typography>
        <Selector
          placeholder="Role"
          labelKey="role_name"
          valueKey="id"
          serviceType="USER_ROLES"
          onChange={(item) => setRole(item)}
          disabled
          autoSelect={props.userType || "Broker"}
        />
      </div>

      <Form
        name="basic"
        initialValues={{
          remember: true,
          ...{ department: department, desgination: desgination },
        }}
        onFinish={handleRegister}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div className="filterWidget" style={{ marginBottom: 12 }}>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please check your email",
              },
            ]}
          >
            <Input
              placeholder="Email"
              label="Email"
              onChange={(e) => setEmail(e.target.value)}
              name="email"
            />
          </Form.Item>
        </div>
        <div className="filterWidget" style={{ marginBottom: 12 }}>
          <Form.Item
            name="firstname"
            rules={[
              {
                required: true,
                message: "Please enter first name",
                fontSize: 12,
              },
            ]}
            style={{ fontSize: 12 }}
          >
            <Input
              placeholder="First Name"
              label="First Name"
              onChange={(e) => setFirstName(e.target.value)}
              name="firstname"
            />
          </Form.Item>
        </div>
        <div className="filterWidget" style={{ marginBottom: 12 }}>
          <Form.Item
            name="lastname"
            rules={[
              {
                required: true,
                message: "Please enter last name!",
                fontSize: 12,
              },
            ]}
            style={{ fontSize: 12 }}
          >
            <Input
              placeholder="Last Name"
              label="Last Name"
              onChange={(e) => setLastName(e.target.value)}
              name="lastname"
            />
          </Form.Item>
          {props.userType === "Employee" ? null : (
            <div
              style={{
                display: "flex",
                alignItems: "flex-start",
                marginTop: 12,
              }}
            >
              <LockResetIcon
                style={{
                  fontSize: 24,
                  color: colorPicker("red"),
                  marginRight: 4,
                }}
              />

              <Typography variant="xs" color="red">
                A random password will be generated by the system and it will be
                shared over email to the user for first time login{" "}
              </Typography>
            </div>
          )}
        </div>

        {props.userType === "Investor" ? (
          <div className="filterWidget" style={{ marginBottom: 12 }}>
            <Form.Item
              name="mobile"
              rules={[
                {
                  required: true,
                  min: 10,
                  message: "Please check your mobile",
                },
              ]}
              style={{ fontSize: 12 }}
            >
              <Input
                placeholder="Mobile"
                label="Mobile"
                onChange={(e) => setMobile(e.target.value)}
                name="mobile"
                max
              />
            </Form.Item>
          </div>
        ) : null}

        {props.userType == "Employee" ? (
          <>
            <div className="filterWidget" style={{ marginBottom: 12 }}>
              <Form.Item
                name="department"
                initialValue={department}
                style={{ fontSize: 12 }}
              >
                <Typography
                  variant="body"
                  weight={400}
                  style={{ marginBottom: 4 }}
                >
                  Department
                </Typography>
                <Selector
                  placeholder="Department"
                  labelKey="name"
                  valueKey="id"
                  onChange={(item) => setDepartment(item.target.value)}
                  serviceType="DEPARTMENTS"
                  name="department"
                />
              </Form.Item>
            </div>
            <div className="filterWidget" style={{ marginBottom: 12 }}>
              <Form.Item name="designation" style={{ fontSize: 12 }}>
                <Typography
                  variant="body"
                  weight={400}
                  style={{ marginBottom: 4 }}
                >
                  Designation
                </Typography>
                <Selector
                  placeholder="Designation"
                  labelKey="name"
                  valueKey="id"
                  onChange={(item) => setDesignation(item.target.value)}
                  serviceType="DESIGNATIONS"
                  disabled={!department}
                  name="designation"
                  queryFilter={{ key: "department_id", dataValue: department }}
                />
              </Form.Item>
              {props.userType === "Employee" ? (
                <div
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    marginTop: 12,
                  }}
                >
                  <LockResetIcon
                    style={{
                      fontSize: 24,
                      color: colorPicker("red"),
                      marginRight: 4,
                    }}
                  />
                  <Typography variant="xs" color="red">
                    A random password will be generated by the system and it
                    will be shared over email to the user for first time login
                  </Typography>
                </div>
              ) : null}
            </div>
          </>
        ) : null}
        <div className="action-buttons">
          <Form.Item style={{ width: "100%" }}>
            <div className="filterWidget" style={{ marginTop: 44 }}>
              <Button
                type="primary"
                size="large"
                style={{ width: "100%" }}
                htmlType="submit"
                loading={isLoading}
              >
                Create
              </Button>
            </div>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}
