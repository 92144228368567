import { Form } from "antd";
import { apiPut } from "app/services/apiService";
import Button from "app/shared/Button";
import Input from "app/shared/InputBox";
import Selector from "app/shared/Selector";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import Typography from "app/shared/Typography";
import React, { useEffect, useState } from "react";
import { connect, useDispatch } from "react-redux";
import { API_ENDPOINT_SETTINGS_UPDATE_USER_INFO } from "../../settings.constans";

function UserProfile(props) {
  const [formData, setFormData] = useState({});
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  useEffect(() => {
    handleInitValues(props.userInfo);
  }, [props.userInfo]);

  const handleInitValues = () => {
    let data = {
      first_name: props.userInfo?.user_profile?.first_name,
      last_name: props.userInfo?.user_profile?.last_name,
      mobile: props.userInfo?.user_profile?.mobile,
      city: props.userInfo?.user_profile?.city,
      gender: props.userInfo?.user_profile?.gender,
    };
    setFormData(JSON.parse(JSON.stringify(data)));
  };
  const handleInputChange = () => {};

  const handleUpdateInfo = async () => {
    setLoading(true);
    const payload = {
      first_name: formData.first_name,
      last_name: formData.last_name,
      mobile: formData.mobile,
      nationality: "India",
      gender: formData.gender,
      city: formData.city,
    };
    const response = await apiPut(
      API_ENDPOINT_SETTINGS_UPDATE_USER_INFO,
      payload
    );
    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };
  return (
    <Form
      fields={[
        {
          name: ["first_name"],
          value: formData.first_name,
        },
        {
          name: ["last_name"],
          value: formData.last_name,
        },
        {
          name: ["mobile"],
          value: formData.mobile,
        },
        {
          name: ["gender"],
          value: formData.gender,
        },
        {
          name: ["city"],
          value: formData.city,
        },
      ]}
      onFinish={handleUpdateInfo}
    >
      <div className="FormGroup">
        <div className="FormElement">
          <Form.Item
            name="first_name"
            // initialValue={formData.saleType}
            required
          >
            <Input
              placeholder="First Name"
              onChange={handleInputChange}
              name="first_name"
              defaultValue={formData.first_name}
              background="white"
              borderColor="black"
              label="First Name"
            />
          </Form.Item>
        </div>
        <div className="FormElement">
          <Form.Item
            name="last_name"
            // initialValue={formData.saleType}
            required
          >
            <Input
              placeholder="First Name"
              onChange={handleInputChange}
              name="last_name"
              defaultValue={formData.last_name}
              background="white"
              borderColor="black"
              label="Last Name"
            />
          </Form.Item>
        </div>

        <div className="FormElement">
          <Form.Item
            name="mobile"
            // initialValue={formData.saleType}
            required
          >
            <Input
              placeholder="Mobile"
              onChange={handleInputChange}
              name="mobile"
              defaultValue={formData.mobile}
              background="white"
              borderColor="black"
              label="Mobile"
            />
          </Form.Item>
        </div>
      </div>
      <div className="FormGroup">
        <div className="FormElement">
          <Form.Item
            name="city"
            // initialValue={formData.saleType}
            required
          >
            <Input
              placeholder="City"
              onChange={handleInputChange}
              name="city"
              defaultValue={formData.city}
              background="white"
              borderColor="black"
              label="City"
            />
          </Form.Item>
        </div>
        <div className="FormElement">
          <Form.Item
            name="gender"
            // initialValue={formData.saleType}
            required
          >
            <Typography
              variant="body"
              weight={400}
              style={{ marginLeft: 4, marginBottom: 4 }}
            >
              Gender
            </Typography>
            <Selector
              placeholder="Plan Type"
              labelKey="name"
              valueKey="id"
              serviceType="STATIC"
              //   onChange={handleInputChange}
              name="interested_in"
              fullObject
              staticData={[
                {
                  id: "MALE",
                  name: "Male",
                },
                {
                  id: "FEMALE",
                  name: "Female",
                },
              ]}
              defaultValue={formData.gender}
            />
          </Form.Item>
        </div>
        <div className="FormElement"></div>
      </div>

      <div className="FormGroup" style={{ justifyContent: "flex-end" }}>
        <div className="FormElement">
          <Form.Item>
            <Button htmlType="submit" loading={isLoading}>
              Update
            </Button>
          </Form.Item>
        </div>
      </div>
    </Form>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
});

export default connect(mapStateToProps, {})(UserProfile);
