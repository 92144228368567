import React, { useMemo, useRef, useState } from "react";
import debounce from "lodash/debounce";
import { Select, Spin } from "antd";
function DebounceSelect({ fetchOptions, debounceTimeout = 800, ...props }) {
  const [fetching, setFetching] = useState(false);
  const [options, setOptions] = useState([]);
  const fetchRef = useRef(0);
  const debounceFetcher = useMemo(() => {
    const loadOptions = (value) => {
      fetchRef.current += 1;
      const fetchId = fetchRef.current;
      setOptions([]);
      setFetching(true);
      fetchOptions(value).then((newOptions) => {
        if (fetchId !== fetchRef.current) {
          // for fetch callback order
          return;
        }
        setOptions(newOptions);
        setFetching(false);
      });
    };
    return debounce(loadOptions, debounceTimeout);
  }, [fetchOptions, debounceTimeout]);
  return (
    <Select
      labelInValue
      filterOption={false}
      onSearch={debounceFetcher}
      notFoundContent={fetching ? <Spin size="small" /> : null}
      {...props}
      options={options}
      size={props.size || "small"}
    />
  );
}

// Usage of DebounceSelect

async function fetchUserList(query) {
  return fetch(
    "https://api.mapbox.com/search/searchbox/v1/suggest?q=" +
      query +
      "&access_token=pk.eyJ1IjoidGFic3MiLCJhIjoiY2xwc2l1OTJrMDRzOTJqdDcycXNvNDFwdiJ9.eilMy87VQzUlUXAWooy0Tw&session_token=pk.eyJ1IjoidGFic3MiLCJhIjoiY2xwc2l1OTJrMDRzOTJqdDcycXNvNDFwdiJ9.eilMy87VQzUlUXAWooy0Tw"
  )
    .then((response) => response.json())
    .then((body) =>
      body.suggestions.map((location) => ({
        label: `${location.name}`,
        value: location.name,
      }))
    );
}
const LocationFinder = (props) => {
  const [value, setValue] = useState([]);
  return (
    <DebounceSelect
      mode="multiple"
      value={value}
      placeholder="Search Location"
      fetchOptions={fetchUserList}
      onChange={(newValue) => {
        props.onChange && props.onChange(newValue);
        setValue(newValue);
      }}
      style={{
        width: "100%",
        height: 40,
        borderRadius: 12,
      }}
    />
  );
};
export default LocationFinder;
