import { ConfigProvider, Form, Modal, Slider, Steps, Upload } from "antd";

import { colorPicker } from "app/utils/helpers/color.helper";
import STATE_LIST from "app/constants/states.json";
import "./property_create.scss";
import { useState } from "react";

import { useDispatch } from "react-redux";
import BasicPropertyInfo from "../PropertyCreate/forms/BasicPropertyInfo";
import PaymentPlanInfo from "../PropertyCreate/forms/PaymentPlanInfo";
import PropertyImages from "../PropertyCreate/forms/PropertyImages";
import { useParams } from "react-router";

export default function PropertyEdit(props) {
  const [createdPropertyId, setCreatedPropertyId] = useState(null);
  const [formStep, setFormStep] = useState(0);
  const dispatch = useDispatch();

  let { propertyId } = useParams();

  const handlePropertyCreate = (id) => {
    setCreatedPropertyId(id);
    setFormStep(1);
  };
  const renderForms = () => {
    switch (formStep) {
      case 0:
        return <BasicPropertyInfo onSuccess={handlePropertyCreate} editMode />;
      case 1:
        return (
          <PaymentPlanInfo
            propertyId={createdPropertyId || propertyId}
            onSuccess={() => setFormStep(2)}
            editMode
            goBack={() => setFormStep(0)}
          />
        );
      case 2:
        return (
          <PropertyImages
            propertyId={createdPropertyId || propertyId}
            editMode
            goBack={() => setFormStep(1)}
          />
        );
      default:
        return <BasicPropertyInfo />;
    }
  };
  return (
    <ConfigProvider
      theme={{
        components: {
          Slider: {
            railBg: "#7C8091",
            dotActiveBorderColor: colorPicker("red"),
            colorText: colorPicker("red"),
            trackBg: colorPicker("red"),
            trackHoverBg: colorPicker("red"),
            handleColor: colorPicker("red"),
            handleActiveColor: colorPicker("red"),
            finishIconBorderColor: colorPicker("red"),
          },
        },
      }}
    >
      <div className="v">
        <ConfigProvider
          theme={{
            components: {
              Steps: {
                navArrowColor: "#73AF00",
                finishIconBorderColor: "#73AF00",
              },
            },
            token: {
              colorPrimary: "#73AF00",
              colorPrimaryBorder: "#73AF0033",
            },
          }}
        >
          <Steps
            current={formStep}
            items={[
              {
                title: "Basic Property Info",
              },
              {
                title: "Payment Plans",
              },
              {
                title: "Images",
              },
            ]}
          />
        </ConfigProvider>
        <div style={{ marginTop: 38, flex: 1 }}>{renderForms()}</div>
      </div>
    </ConfigProvider>
  );
}
