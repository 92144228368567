import Button from "app/shared/Button";
import Typography from "app/shared/Typography";
import { useState } from "react";
import { MailOutlined } from "@ant-design/icons";

import "../../styles/auth.scss";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router";
import { apiPost } from "app/services/apiService";
import { API_ENDPOINT_AUTH_VERIFY_RESEND_EMAIL } from "../../auth.constants";
import { connect, useDispatch } from "react-redux";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import { colorPicker } from "app/utils/helpers/color.helper";

function SignupSuccess(props) {
  const [counter, setCounter] = useState(0);
  const [isLoading, setLoading] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const dispatch = useDispatch();
  const emailId = location.state.email;
  useEffect(() => {
    let interval = null;
    interval = setInterval(() => {
      // Must use callback form here; outer `counter` is in stale closure
      setCounter((counter) => (counter === 0 ? counter : counter - 1));
    }, 1000);
    return () => {
      clearInterval(interval);
    };
  }, []);

  const resendVerification = async () => {
    setCounter(60);
    setLoading(true);
    const response = await apiPost(API_ENDPOINT_AUTH_VERIFY_RESEND_EMAIL, {
      email: emailId,
    });
    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };

  return (
    <div>
      <Typography style={{ textAlign: "center" }} variant="h5">
        <MailOutlined style={{ color: colorPicker("red") }} /> We have send you
        an verificaiton link to your registered email
      </Typography>
      {emailId ? (
        <Button
          size="large"
          style={{ width: "100%" }}
          type="link"
          danger
          disabled={counter > 0}
          onClick={resendVerification}
          loading={isLoading}
        >
          {counter === 0 ? "Send again" : `Send again in ${counter}`}
        </Button>
      ) : (
        <Button
          size="large"
          style={{ width: "100%" }}
          type="link"
          danger
          onClick={() => navigate("/auth/authenticate")}
        >
          Try login to get verification link again
        </Button>
      )}
    </div>
  );
}

const mapStateToProps = (state) => ({
  userInfo: state.auth.userInfo,
});

export default connect(mapStateToProps, {})(SignupSuccess);
