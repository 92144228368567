import { combineReducers } from "redux";

import authReducer from "app/scenes/auth/redux/auth.reducer";
import toastReducer from "app/shared/Toast/reducers";

export const reducerMap = {
  auth: authReducer,
  toasts: toastReducer,
};
//eslint-disable-next-line
export default () => {
  return combineReducers(reducerMap);
};
