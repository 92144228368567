import { UserOutlined } from "@ant-design/icons";
import { Avatar, Image, Popconfirm, Switch, Tag } from "antd";
import TextArea from "antd/es/input/TextArea";
import {
  ACCOUNT_STATUS,
  ACCOUNT_STATUS_MAP_COLOR,
} from "app/constants/global.constants";
import { apiGet, apiPost } from "app/services/apiService";
import Button from "app/shared/Button";
import Loader from "app/shared/Loader";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import Typography from "app/shared/Typography";
import { parseURL } from "app/utils/helpers/common.helper";
import moment from "moment";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import {
  API_ENDPOINT_BROKER_INFO,
  API_ENDPOINT_USER_INFO,
  API_ENDPOINT_USER_UPDATE_ACCOUNT_STATUS,
} from "../../brokers.constants";
import "./BrokerDetails.scss";
import DummyAvatar from "assets/images/DummyAvatar.png";
import DashboardOutlinedIcon from "@mui/icons-material/DashboardOutlined";
import { useNavigate } from "react-router";
export default function BrokerDetails(props) {
  const [brokerInfo, setSetBrokerInfo] = useState(null);
  const [refererInfo, setRefererInfo] = useState(null);

  const [isLoading, setLoading] = useState(true);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  useEffect(() => {
    fetchBrokerInfo();
  }, [props.selectedUser]);
  const fetchBrokerInfo = async () => {
    setLoading(true);
    const response = await apiGet(
      API_ENDPOINT_USER_INFO + props.selectedUser
    );
    if (response.status) {
      setSetBrokerInfo(response.data);
      if (response.data.profileInfo.refer_by) {
        getRefererInfo(response.data.profileInfo.refer_by);
      } else {
        setRefererInfo(null);
      }
    } else {
      setSetBrokerInfo(null);
      setRefererInfo(null);
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };
  const getRefererInfo = async (userId) => {
    const response = await apiGet(API_ENDPOINT_USER_INFO + userId);
    if (response.status) {
      setRefererInfo(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };

  const handleUserAccountStatus = async (status) => {
    const response = await apiPost(
      parseURL(API_ENDPOINT_USER_UPDATE_ACCOUNT_STATUS, {
        userId: props.selectedUser,
        status: status,
      })
    );
    if (response.status) {
      fetchBrokerInfo();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
    }
  };
  return isLoading ? (
    <Loader />
  ) : brokerInfo === null ? (
    <div>
      <Typography>No Profile</Typography>
    </div>
  ) : (
    <div className="BrokerDetails">
      <div className="DetailRow">
        <div className="Info">
          <Typography variant="body" style={{ marginBottom: 4 }}>
            Status
          </Typography>
          <Tag
            color={
              ACCOUNT_STATUS_MAP_COLOR[
                brokerInfo && brokerInfo.profileInfo?.status
              ]
            }
          >
            {brokerInfo && brokerInfo.profileInfo?.status}
          </Tag>
        </div>
        <div className="Info">
          <Typography variant="body" style={{ marginBottom: 4 }}>
            Account (Enable/Disable)
          </Typography>
          <Popconfirm
            title={`Are you sure want to ${
              brokerInfo && brokerInfo.profileInfo?.status === "ACTIVE"
                ? "disable"
                : "enable"
            } this account`}
            // description="Are you sure to delete this task?"
            onConfirm={() =>
              handleUserAccountStatus(
                brokerInfo && brokerInfo.profileInfo?.status === "ACTIVE"
                  ? ACCOUNT_STATUS.DISABLED
                  : ACCOUNT_STATUS.ACTIVE
              )
            }
            onCancel={null}
            okText="Yes"
            cancelText="No"
          >
            <Switch
              size="small"
              checked={
                brokerInfo && brokerInfo.profileInfo?.status === "ACTIVE"
              }
              disabled={
                brokerInfo && brokerInfo.profileInfo?.status === "PENDING"
              }
            />
          </Popconfirm>
        </div>
      </div>
      <div className="DetailRow">
        <div className="Info">
          <Typography variant="body" style={{ marginBottom: 4 }}>
            Name
          </Typography>
          <Typography color="gray-dark">
            {brokerInfo && brokerInfo.profileInfo.user_profile.first_name}{" "}
            {brokerInfo && brokerInfo.profileInfo.user_profile.last_name}
          </Typography>
        </div>
        <div className="Info">
          <Typography variant="body" style={{ marginBottom: 4 }}>
            Mobile
          </Typography>
          <Typography color="gray-dark">
            {brokerInfo && brokerInfo.profileInfo.user_profile.mobile}
          </Typography>
        </div>
      </div>
      <div className="DetailRow">
        <div className="Info">
          <Typography variant="body" style={{ marginBottom: 4 }}>
            Email
          </Typography>
          <Typography color="gray-dark">
            {brokerInfo && brokerInfo.profileInfo.email}
          </Typography>
        </div>
        <div className="Info">
          <Typography variant="body" style={{ marginBottom: 4 }}>
            City
          </Typography>
          <Typography color="gray-dark">
            {(brokerInfo && brokerInfo.profileInfo.user_profile.city) || "NA"}
          </Typography>
        </div>
      </div>

      <div className="DetailRow">
        <div className="Info">
          <Typography variant="body" style={{ marginBottom: 4 }}>
            Created on
          </Typography>
          <Typography color="gray-dark">
            {moment(brokerInfo && brokerInfo.profileInfo.createdAt).format(
              "DD MMM YY"
            )}
          </Typography>
        </div>
        <div className="Info">
          <Typography variant="body" style={{ marginBottom: 4 }}>
            Gender
          </Typography>
          <Typography color="gray-dark">
            {(brokerInfo && brokerInfo.profileInfo.user_profile.gender) || "NA"}
          </Typography>
        </div>
      </div>

      {props.userType && props.userType === "Employee" ? (
        <div className="DetailRow">
          <div className="Info">
            <Typography variant="body" style={{ marginBottom: 4 }}>
              Department
            </Typography>
            <Typography color="gray-dark">
              {brokerInfo &&
                brokerInfo?.profileInfo.user_designation?.user_department?.name}
            </Typography>
          </div>
          <div className="Info">
            <Typography variant="body" style={{ marginBottom: 4 }}>
              Designation
            </Typography>
            <Typography color="gray-dark">
              {brokerInfo && brokerInfo?.profileInfo.user_designation?.name}
            </Typography>
          </div>
        </div>
      ) : null}
      <div className="DetailRow">
        {brokerInfo &&
          brokerInfo.profileInfo.user_documents &&
          brokerInfo.profileInfo.user_documents.map((document, key) => {
            return (
              <div className="Info" kye={key}>
                <Typography variant="body" style={{ marginBottom: 4 }}>
                  {document.type} Number
                </Typography>
                <Typography color="gray-dark">
                  {document.document_no}
                </Typography>
              </div>
            );
          })}
      </div>

      <div className="DetailRow">
        {brokerInfo &&
          brokerInfo.profileInfo.user_documents &&
          brokerInfo.profileInfo.user_documents.map((document, key) => {
            return (
              <div className="Info" kye={key}>
                <Typography variant="body" style={{ marginBottom: 4 }}>
                  {document.type} Image
                </Typography>
                <Image
                  width={100}
                  height={100}
                  src={document.url_image_front}
                />
              </div>
            );
          })}
      </div>
      {refererInfo ? (
        <div className="DetailRow">
          <div className="ReferDetail">
            <Typography variant="body" style={{ marginBottom: 4 }}>
              Refferal Detail
            </Typography>
            <div className="UserInfo">
              <Image
                width={78}
                style={{ borderRadius: 12 }}
                src={
                  (refererInfo && refererInfo.user_profile?.avatar) ||
                  DummyAvatar
                }
              />
              <div className="ReferalInfo">
                <div className="nameInfo">
                  <Typography
                    style={{ width: "50%" }}
                    variant="xs"
                    color="gray-dark"
                  >
                    Name :
                  </Typography>
                  <Typography variant="xs" color="gray-dark">
                    {refererInfo &&
                      refererInfo.profileInfo.user_profile.first_name}{" "}
                    {refererInfo &&
                      refererInfo.profileInfo.user_profile.last_name}
                  </Typography>
                </div>
                <div className="nameInfo">
                  <Typography
                    style={{ width: "50%" }}
                    variant="xs"
                    color="gray-dark"
                  >
                    Email :
                  </Typography>
                  <Typography variant="xs" color="gray-dark">
                    {refererInfo && refererInfo.profileInfo.email}
                  </Typography>
                </div>
                <div className="nameInfo">
                  <Typography
                    style={{ width: "50%" }}
                    variant="xs"
                    color="gray-dark"
                  >
                    Phone :
                  </Typography>
                  <Typography variant="xs" color="gray-dark">
                    {refererInfo && refererInfo.profileInfo.user_profile.mobile}
                  </Typography>
                </div>
                <div className="nameInfo">
                  <Typography
                    style={{ width: "50%" }}
                    variant="xs"
                    color="gray-dark"
                  >
                    Referal Code :
                  </Typography>
                  <Typography variant="xs" color="gray-dark">
                    {refererInfo &&
                      refererInfo.profileInfo.user_profile.member_id}
                  </Typography>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : null}

      {brokerInfo && brokerInfo.profileInfo?.status === "PENDING" ? (
        <div className="DetailRow" style={{ marginTop: 24 }}>
          <div className="Info" style={{ width: "100%" }}>
            <Typography variant="body" style={{ marginBottom: 4 }}>
              Comments
            </Typography>
            <TextArea
              rows={4}
              style={{ backgroundColor: "#f4f6f9", width: "100%" }}
              autoSize={false}
              placeholder="add your comments here"
            />
          </div>
        </div>
      ) : null}

      {brokerInfo && brokerInfo.profileInfo?.status === "PENDING" ? (
        <div className="DetailRow" style={{ marginTop: 24 }}>
          <div className="Info" style={{ width: "45%" }}>
            <Popconfirm
              title={`Are you sure want to approve this account`}
              // description="Are you sure to delete this task?"
              onConfirm={() => handleUserAccountStatus(ACCOUNT_STATUS.ACTIVE)}
              onCancel={null}
              okText="Yes"
              cancelText="No"
            >
              <Button background="green">Approved</Button>
            </Popconfirm>
          </div>
          <div className="Info" style={{ width: "45%" }}>
            <Popconfirm
              title={`Are you sure want to reject this account`}
              // description="Are you sure to delete this task?"
              onConfirm={() => handleUserAccountStatus(ACCOUNT_STATUS.REJECTED)}
              onCancel={null}
              okText="Yes"
              cancelText="No"
            >
              <Button>Reject</Button>
            </Popconfirm>
          </div>
        </div>
      ) : null}
    </div>
  );
}
