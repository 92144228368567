import React, { useState } from "react";
import "./LocationPicker.scss";
import RoomIcon from "@mui/icons-material/Room";
import Typography from "../Typography";
import { colorPicker } from "app/utils/helpers/color.helper";
import MapBox from "../MapBox";
import { Modal } from "antd";
import { useEffect } from "react";
export default function LocationPicker(props) {
  console.log("props:", props);
  useEffect(() => {
    let options = {
      enableHighAccuracy: true,
      timeout: 5000,
      maximumAge: 0,
    };
    if (navigator.geolocation) {
      navigator.permissions
        .query({ name: "geolocation" })
        .then(function (result) {
          console.log(result);
          if (result.state === "granted") {
            navigator.geolocation.getCurrentPosition(success, errors, options);
            //If granted then you can directly call your function here
          } else if (result.state === "prompt") {
            //If prompt then the user will be asked to give permission
          } else if (result.state === "denied") {
            //If denied then you have to show instructions to enable location
          }
        });
    } else {
      console.log("Geolocation is not supported by this browser.");
    }
  }, []);

  const success = (pos) => {
    var crd = pos.coords;
  };

  const errors = (err) => {
    console.warn(`ERROR(${err.code}): ${err.message}`);
  };

  const [showModal, setShowModal] = useState(false);
  return (
    <div className="LocationPicker">
      <div className="PickerButton" onClick={() => setShowModal(true)}>
        <RoomIcon style={{ color: colorPicker("red"), fontSize: 18 }} />
        <Typography style={{ marginLeft: 4 }} color="gray-dark">
          {props.defaultValue
            ? `${parseFloat(props.defaultValue.split(",")[0]).toFixed(
                5
              )},${parseFloat(props.defaultValue.split(",")[1]).toFixed(5)}`
            : "Choose location from map"}
        </Typography>
      </div>
      <Modal
        open={showModal}
        onCancel={() => setShowModal(false)}
        footer={false}
        width={600}
        bodyStyle={{ height: 400 }}
      >
        <MapBox
          pickMarker={!props.defaultValue}
          selectCordinate={(cordinate) => {
            setShowModal(false);
            props.selectCordinate && props.selectCordinate(cordinate);
          }}
          defaultValue={{
            latitude:
              (props.defaultValue && props.defaultValue.split(",")[0]) || 0,
            longitude:
              (props.defaultValue && props.defaultValue.split(",")[1]) || 0,
          }}
        />
      </Modal>
    </div>
  );
}
