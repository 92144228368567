/* API ENDPOINTS */
export const API_ENDPOINT_BROKERS_LIST = `${process.env.REACT_APP_API_URL}/brokers/list`;
export const API_ENDPOINT_BROKERS_INVITE = `${process.env.REACT_APP_API_URL}/user/invite`;
export const API_ENDPOINT_USER_INFO = `${process.env.REACT_APP_API_URL}/user/`;
export const API_ENDPOINT_USER_UPDATE_ACCOUNT_STATUS = `${process.env.REACT_APP_API_URL}/user/{{userId}}/status/{{status}}`;
export const API_ENDPOINT_FETCH_BROKER_REFERRALS = `${process.env.REACT_APP_API_URL}/brokers/{{brokerId}}/referrals`;
export const API_ENDPOINT_FETCH_BROKER_DASHBOARD_GIST = `${process.env.REACT_APP_API_URL}/brokers/{{brokerId}}/dashboard/gist`;
export const API_ENDPOINT_FETCH_BROKER_COMISSION_GIST = `${process.env.REACT_APP_API_URL}/brokers/{{brokerId}}/comissions/gist`;
export const API_ENDPOINT_FETCH_BROKER_COMISSION_EARNING_LIST = `${process.env.REACT_APP_API_URL}/brokers/{{brokerId}}/comissions/list?type=`;
export const API_ENDPOINT_FETCH_BROKER_ACITIVTY = `${process.env.REACT_APP_API_URL}/brokers/{{brokerId}}/dashboard/activity`;
export const API_ENDPOINT_FETCH_BROKER_SALES_INFO = `${process.env.REACT_APP_API_URL}/brokers/{{brokerId}}/sales/info`;
export const API_ENDPOINT_FETCH_BROKER_VISIT_INFO = `${process.env.REACT_APP_API_URL}/brokers/{{brokerId}}/visit/info`;
export const API_ENDPOINT_BROKERS_PENDING_COMISSIONS = `${process.env.REACT_APP_API_URL}/brokers/comisssion/list?type=`;
export const API_ENDPOINT_USER_UPDATE_PENDING_COMISSION_STATUS = `${process.env.REACT_APP_API_URL}/brokers/{{brokerId}}/comissions/{{comissionId}}/status/{{status}}`;
