import { Form, Slider, Typography } from "antd";
import { API_ENDPOINT_BROKERS_INVITE } from "app/scenes/brokers/brokers.constants";
import { apiPost } from "app/services/apiService";
import Button from "app/shared/Button";
import Input from "app/shared/InputBox";
import LocationFinder from "app/shared/LocationFinder";
import Selector from "app/shared/Selector";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import { useState } from "react";
import { useDispatch } from "react-redux";

const userTypeMap = {
  PROMOTER: "Promoter",
};
export default function InviteBroker(props) {
  const [role, setRole] = useState("");
  const [email, setEmail] = useState("");
  const [referal, setReferral] = useState("");
  const [isLoading, setLoading] = useState(false);

  const dispatch = useDispatch();
  const handleInvite = async () => {
    setLoading(true);
    const payload = {
      email: email,
      referral_code: referal,
      role_id: role || "03a91eff-e871-450e-82b4-a4771b02ca4f",
    };

    const response = await apiPost(API_ENDPOINT_BROKERS_INVITE, payload);
    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
      props.onInvite && props.onInvite();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };
  return (
    <div>
      <div className="filterWidget" style={{ marginBottom: 12 }}>
        <Typography variant="xs" style={{ marginBottom: 4 }}>
          Role
        </Typography>
        <Selector
          placeholder="Role"
          labelKey="role_name"
          valueKey="id"
          serviceType="USER_ROLES"
          onChange={(item) => setRole(item)}
          disabled
          autoSelect={props.userType || "Broker"}
        />
      </div>

      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={handleInvite}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div className="filterWidget" style={{ marginBottom: 12 }}>
          <Form.Item
            name="email"
            rules={[
              {
                required: true,
                type: "email",
                message: "Please check your email",
              },
            ]}
          >
            <Input
              placeholder="Email"
              label="Email"
              onChange={(e) => setEmail(e.target.value)}
              name="email"
            />
          </Form.Item>
        </div>
        <div className="filterWidget" style={{ marginBottom: 12 }}>
          <Form.Item
            name="referal"
            rules={[
              {
                required: true,
                message: "Please enter your code!",
                fontSize: 12,
              },
            ]}
            style={{ fontSize: 12 }}
          >
            <Input
              placeholder="Referral Code"
              label="Referral Code"
              onChange={(e) => setReferral(e.target.value)}
              name="referral"
            />
          </Form.Item>
        </div>

        <div className="action-buttons">
          <Form.Item style={{ width: "100%" }}>
            <div className="filterWidget" style={{ marginTop: 44 }}>
              <Button
                type="primary"
                size="large"
                style={{ width: "100%" }}
                htmlType="submit"
                loading={isLoading}
              >
                Invite
              </Button>
            </div>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}
