import { Image } from "antd";
import React from "react";
import "./PhotoGallery.scss";
export default function PhotoGallery(props) {
  return (
    <div className="PhotoGallery">
      <Image.PreviewGroup>
        {(props.images || []).map((image, key) => {
          return (
            <div className="Grid">
              <Image key={key} width={100} src={image[props.imageKey]} />
            </div>
          );
        })}
      </Image.PreviewGroup>
    </div>
  );
}
