// import ProtectedRoute from "app/utils/ProtectedRoute";
import ProtectedRoute from "app/utils/ProtectedRoute";
import Announcement from ".";
import AnnouncementList from "./components/AnnouncementList/AnnouncementList";
//eslint-disable-next-line
export default {
  path: "/announcement",
  element: <Announcement />,
  children: [
    {
      path: "/announcement/list",
      element: (
        <ProtectedRoute accessKey="PROMOTER_VIEW">
          <AnnouncementList />
        </ProtectedRoute>
      ),
      exact: true,
    },
  ],
};
