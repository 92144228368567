import { DeleteOutlined, PlusCircleOutlined } from "@ant-design/icons";
import { Form, message, Upload } from "antd";
import Button from "app/shared/Button";
import Input from "app/shared/InputBox";
import Selector from "app/shared/Selector";
import Typography from "app/shared/Typography";
import { useState } from "react";
import "./AddAmentity.scss";
import Papa from "papaparse";
import { apiGet, apiPost } from "app/services/apiService";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import {
  API_ENDPOINT_GLOBAL_ADD_AMENTITY,
  API_ENDPOINT_GLOBAL_ADD_PROPERTY_TYPES,
  API_ENDPOINT_PROPERTY_DEVELOPER_ADD,
} from "app/scenes/property/property.constants";
export default function AddPropertyType(props) {
  const [name, setName] = useState("");
  const [estd, setEstd] = useState("");
  const [isLoading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const handleAddAmentity = async () => {
    setLoading(true);
    const response = await apiPost(API_ENDPOINT_PROPERTY_DEVELOPER_ADD, {
      name: name,
      estd: estd,
    });
    if (response.status) {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
      props.onCreate && props.onCreate();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };

  return (
    <div className="PropertyTypeAdd">
      <Form
        name="basic"
        initialValues={{ remember: true }}
        onFinish={handleAddAmentity}
        // onFinishFailed={onFinishFailed}
        autoComplete="off"
      >
        <div className="formContainer">
          <div className="form-row">
            <div className="input-element">
              <Form.Item
                name={"name"}
                rules={[
                  {
                    required: true,
                    message: "Please check your input",
                  },
                ]}
                style={{ marginBottom: 0 }}
              >
                <Input
                  placeholder="Developer Name"
                  name={"name"}
                  onChange={(e) => setName(e.target.value)}
                />
              </Form.Item>
            </div>

            <div className="input-element">
              <Form.Item name={"estd"} style={{ marginBottom: 0 }}>
                <Input
                  placeholder="Estd (eg: 2023)"
                  name={"estd"}
                  onChange={(e) => setEstd(e.target.value)}
                />
              </Form.Item>
            </div>
            <div className="input-element"></div>
          </div>
        </div>
        {/* <Upload
          {...props}
          accept=".csv"
          beforeUpload={beforeUpload}
          customRequest={null}
          action="https://run.mocky.io/v3/435e224c-44fb-4773-9faf-380c5e6a2188"
        >
          <Typography
            variant="xs"
            color="primary"
            style={{ cursor: "pointer" }}
          >
            Add Bulk Amentity
          </Typography>
        </Upload> */}

        <div className="action-buttons">
          <Form.Item style={{ width: 240 }}>
            <Button
              type="primary"
              size="large"
              style={{ width: "100%" }}
              htmlType="submit"
              loading={isLoading}
            >
              Create
            </Button>
          </Form.Item>
        </div>
      </Form>
    </div>
  );
}
