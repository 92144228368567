import {
  CheckOutlined,
  DeleteOutlined,
  EditFilled,
  SearchOutlined,
} from "@ant-design/icons";
import { Drawer, Modal, Popconfirm, Switch, Tabs, Tag } from "antd";
import { ACCOUNT_STATUS } from "app/constants/global.constants";
import { apiDelete, apiGet, apiPost, apiPut } from "app/services/apiService";
import Button from "app/shared/Button";
import CreateInternalUser from "app/shared/InternalUserCreate";
import TabBar from "app/shared/TabBar";
import TableView from "app/shared/TableView";
import { ADD_TOAST_MESSAGE } from "app/shared/Toast/toast.constants";
import Typography from "app/shared/Typography";
import { FeatureFlags } from "app/utils/FeatureFlag/FeatrueFlag";
import { parseURL } from "app/utils/helpers/common.helper";

import { useContext, useEffect } from "react";
import { useState } from "react";
import { useDispatch } from "react-redux";
import {
  API_ENDPOINT_GLOBAL_DELETE_AMENTITY,
  API_ENDPOINT_GLOBAL_DELETE_FACILITY,
  API_ENDPOINT_GLOBAL_FETCH_AMENTITY_LIST,
  API_ENDPOINT_GLOBAL_UPDATE_MASTER_DATA_STATUS,
  API_ENDPOINT_PROJECT_INVESTORS_LIST,
  API_ENDPOINT_PROPERTY_FETCH_LIST_COMMON,
} from "../../property.constants";

import "./FacilityList.scss";
import AddFacility from "./components/AddFacility";
import EditFacility from "./components/EditFacility";

const tableColumn = [
  {
    title: "Name",
    index: "name",
    search: true,
  },
  {
    title: "Description",
    index: "description",
  },
  {
    title: "Icon",
    index: "icon",
  },
  {
    title: "Status",
    index: "status",
    handleAction: (propsData) => propsData.handleAction(propsData),
    child: (childProps) => (
      <Popconfirm
        title={`Are you sure want to ${
          childProps.status === "ACTIVE" ? "disable" : "enable"
        } this account`}
        // description="Are you sure to delete this task?"
        onConfirm={() =>
          childProps.handleSwitch(
            childProps.id,
            childProps.status === "ACTIVE"
              ? ACCOUNT_STATUS.DISABLED
              : ACCOUNT_STATUS.ACTIVE
          )
        }
        onCancel={null}
        okText="Yes"
        cancelText="No"
      >
        <Switch
          size="small"
          checked={childProps.status === "ACTIVE"}
          disabled={childProps.status === "PENDING"}
        />
      </Popconfirm>
    ),
  },
];

const actionColumnObj = {
  title: "Action",
  renderAction: true,
  handleAction: (propsData) => propsData.handleAction(propsData),
  actions: [
    {
      event: "edit",
      icon: <EditFilled style={{ fontSize: 12, color: "white" }} />,
      color: "#f0932b",
      showOn: (data) => data.hasAccess,
      accessKey: "PROMOTER_MANAGE",
    },
    {
      event: "delete",
      icon: <DeleteOutlined style={{ fontSize: 12, color: "white" }} />,
      color: "#FF0000",
      popup: true,
      popupTitle: "Are you sure want to delete?",
      showOn: (data) => data.hasAccess,
      accessKey: "PROMOTER_MANAGE",
    },
  ],
};
const LIST_TYPE_MAP = {
  1: "ALL",
  2: "APPROVED",
  3: "PENDING",
  4: "REJECTED",
  5: "INVITED",
};

export default function FacilityList(props) {
  const [isLoading, setLoading] = useState(true);
  const [showDrawer, setShowDrawer] = useState(false);
  const [amentities, setAmentities] = useState([]);
  const [selectedData, setSelectedData] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const dispatch = useDispatch();
  const { hasAccess } = useContext(FeatureFlags);

  useEffect(() => {
    fetchAmentityList();
  }, []);
  const fetchAmentityList = async () => {
    setLoading(true);
    const response = await apiGet(
      API_ENDPOINT_PROPERTY_FETCH_LIST_COMMON +
        "?service=FACILITIES"
    );
    if (response.status) {
      setAmentities(response.data);
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 0 },
      });
    }
    setLoading(false);
  };
  const handleShowDrawer = (type) => {
    // setShowDrawerFor(type);
    setShowDrawer(true);
  };

  const handleDeleteAmentity = async (amentityId) => {
    const response = await apiDelete(
      API_ENDPOINT_GLOBAL_DELETE_FACILITY + amentityId
    );
    if (response.status) {
      fetchAmentityList();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
    }
  };

  const handleTableAction = (event) => {
    switch (event.eventType) {
      case "delete":
        handleDeleteAmentity(event.rowData.id);
      case "edit":
        setSelectedData(event.rowData);
        setShowDrawer(true);
        return;
      case "toggle":
        handleUserAccountStatus(event.rowData.id, event.rowData.NEW_STATUS);
        return;
      default:
        return null;
    }
  };

  const handleUserAccountStatus = async (userId, status) => {
    const response = await apiPut(
      parseURL(API_ENDPOINT_GLOBAL_UPDATE_MASTER_DATA_STATUS, {
        masterDataId: userId,
        statusId: status,
      }) + "?serviceType=FACILITY"
    );
    if (response.status) {
      fetchAmentityList();
    } else {
      dispatch({
        type: ADD_TOAST_MESSAGE,
        payload: { message: response.message, type: 1 },
      });
    }
  };

  return (
    <div className="PromoterList">
      <div className="TopSelectors">
        <div className="leftSelectors"></div>
        <div className="rightSelectors">
          {hasAccess("PROPERTY_LISTING_MANAGE") ? (
            <div className="WidgetContainer" style={{ minWidth: 0 }}>
              <Button onClick={() => setShowModal(true)}>Add Facility</Button>
            </div>
          ) : null}
        </div>
      </div>
      <div id="tabBarContainer">
        <TableView
          data={amentities}
          isLoading={isLoading}
          column={[...tableColumn, actionColumnObj]}
          handleAction={handleTableAction}
          hasAccess={hasAccess}
        />
      </div>

      <Modal
        title={"Add Facility"}
        open={showModal}
        footer={false}
        onCancel={() => setShowModal(false)}
        width={800}
      >
        <AddFacility
          onCreate={() => {
            setShowModal(false);
            fetchAmentityList();
          }}
        />
      </Modal>

      <Drawer
        title="Edit Facility"
        placement={"right"}
        width={500}
        onClose={() => setShowDrawer(false)}
        open={showDrawer}
      >
        <EditFacility
          data={selectedData}
          onCreate={() => {
            setShowDrawer(false);
            fetchAmentityList();
          }}
          serviceType="FACILITY"
        />
      </Drawer>
    </div>
  );
}
